/*
 * IMPORTS
 */
import 'nprogress/nprogress.css' // Npm: Progress bar.
import React from 'react' // Npm: React.js library.
import PropTypes from 'prop-types' // Npm: React.js prop types for type checking.
import NProgress from 'nprogress' // Npm: Progress bar.
import _ from 'underscore' // Npm: Utility module.
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom' // Npm: React router dom library.
import { connect } from 'react-redux' // Npm: react native redux library.
import { Toaster } from 'react-hot-toast' // Npm: React hot toast.
import { FaRoad } from 'react-icons/fa' // Npm: React icons.
import { ApolloProvider, useMutation, useQuery, useLazyQuery, useSubscription } from '@apollo/client' // Npm: Apollo client for handling graphql.
import { Box, Text, SimpleGrid, useTheme } from '@chakra-ui/react' // Npm: A simple, modular and accessible component library for React.js.
import {
  HiCodeBracketSquare,
  HiCurrencyEuro,
  HiHome,
  HiCurrencyDollar,
  HiNoSymbol,
  HiBuildingStorefront,
  HiServer,
  HiBookOpen,
  HiChartBarSquare,
  HiUserGroup
} from 'react-icons/hi2' // Npm: React icons.


/*
 * PACKAGES
 */
import SideBarNavigation from 'components/SideBar'
import SideBarNavigationContext from 'components/SideBar/index.context'
import NavigationBar from 'components/NavBar'
import DlrPercentage from 'components/DlrPercentage'
import TotalSmsDelivered from 'components/TotalSmsDelivered'
import TotalSmsFailed from 'components/TotalSmsFailed'
import TotalSmsSent from 'components/TotalSmsSent'


/*
 * APOLLO
 */
import { ApolloClientProvider } from './index.apollo.client'


/*
 * STYLES
 */
import 'mapbox-gl/dist/mapbox-gl.css'


/*
 * SCREENS
 */
import Page404 from './pages/404'
import PageLogin from './pages/Login'
import PageMasterView from './pages/MasterView'
import PageAccountDeveloper from './pages/Account/Developer'
import PagePriceAndCoverage from './pages/PriceAndCoverage'
import PageContactBookDirectory from './pages/ContactBook'
import PageContactBookDirectoryContactBook from './pages/ContactBook/ContactBook'
import PageCredit from './pages/Credit'
import PageCreditHistory from './pages/Credit/History'
import PageRoute from './pages/Route'
import PageRoutePlan from './pages/Route/Plan'
import PageDltDirectory from './pages/Dlt'
import PageDltDirectoryDlt from './pages/Dlt/Dlt'
import PageCustomer from './pages/Customer'
import PageCustomerAccount from './pages/Customer/Account'
import PageCustomerPayment from './pages/Customer/Payment'
import PageRate from './pages/Rate'
import PageRatePlan from './pages/Rate/Plan'
import PageVendorReport from './pages/Vendor/Report'
import PagesCustomerMargin from './pages/Customer/Margin'


/*
 * GRAPHS
 */
import NotifyReadQuery from './__query__/index.notify.read.query'
import RouteReadQuery from './__query__/index.route.read.query'
import AccountReadSubscription from './__subscription__/index.account.read.subscription'
import AccountLogoutMutation from './__mutation__/index.account.logout.mutation'
import AccountReadQuery from './__query__/index.account.read.query'
import CustomerReadQuery from './__query__/index.customer.read.query'


/*
 * OBJECTS
 */
const Index = ({ customer, vendor, account, AccountUpdate, AccountLogout, ClearEverything, CustomerUpdate, RouteUpdate, ...props }) => {
  // Const assignment.
  const _ApolloClientInstance_ = React.useRef(ApolloClientProvider(account, { ClearEverything }))
  const _letUIOverflowFollowingPages = ['/', '/account/developer', '/vendor/report']
  const _routes = account?.accountType === 'TERMINATION' || vendor?.id ? ([
    {
      'name': 'Login',
      'path': '/login',
      'icon': <></>,
      'collapse': false,
      'hide': true,
      'noLayout': true,
      'component': PageLogin
    },
    {
      'name': 'Vendor Report',
      'path': '/vendor',
      'icon': <HiChartBarSquare size={21} />,
      'collapse': false,
      'component': PageVendorReport,
      'items': [
        {
          'name': 'Vendor Report',
          'path': '/vendor/report',
          'icon': <HiChartBarSquare size={21} />,
          'collapse': false,
          'component': PageVendorReport
        }
      ]
    }]) : _.compact([
      {
        'name': 'Master View',
        'path': '/',
        'icon': <HiHome size={21} />,
        'collapse': false,
        'component': PageMasterView
      },
      {
        'name': 'Login',
        'path': '/login',
        'icon': <></>,
        'collapse': false,
        'hide': true,
        'noLayout': true,
        'component': PageLogin
      },
      account?.accountType === 'RESELLER' ? {
        'name': 'Customer Management',
        'path': '/customer',
        'icon': <HiUserGroup size={21} />,
        'component': PageCustomer,
        'items': [
          {
            'name': 'Customer Smpp',
            'path': '/customer/smpp',
            'component': PageCustomerAccount
          },
          {
            'name': 'Customer Payment',
            'path': '/customer/payment',
            'component': PageCustomerPayment
          },
          {
            'name': 'Customer Margin',
            'path': '/customer/margin',
            'component': PagesCustomerMargin
          }
        ]
      } : void 0,
      account?.accountType === 'RESELLER' ? {
        'name': 'Credit Management',
        'path': '/credit',
        'icon': <HiCurrencyEuro size={21} />,
        'component': PageCredit,
        'items': [
          {
            'name': 'Credit Management',
            'path': '/credit',
            'icon': <HiServer size={17} />,
            'component': PageCredit
          },
          {
            'name': 'Credit History',
            'path': '/credit/history',
            'icon': <HiServer size={17} />,
            'component': PageCreditHistory
          }
        ]
      } : void 0,
      account?.accountType === 'RESELLER' ? {
        'name': 'Rate Management',
        'path': '/rate',
        'icon': <HiCurrencyDollar size={21} />,
        'component': PageRate,
        'items': [
          {
            'name': 'Rate Plan',
            'path': '/rate/plan',
            'component': PageRatePlan
          }
        ]
      } : void 0,
      account?.accountType === 'RESELLER' ? {
        'name': 'Route Management',
        'path': '/route',
        'icon': <FaRoad size={21} />,
        'component': PageRoute,
        'items': [
          {
            'name': 'Route Plan',
            'path': '/route/plan',
            'component': PageRoutePlan
          }
        ]
      } : void 0,
      {
        'name': 'Price And Coverage',
        'path': '/priceandcoverage',
        'icon': <HiCurrencyEuro size={21} />,
        'collapse': false,
        'component': PagePriceAndCoverage
      },
      {
        'name': 'Developer Account',
        'path': '/account/developer',
        'icon': <HiCodeBracketSquare size={21} />,
        'collapse': false,
        'component': PageAccountDeveloper
      },
      {
        'name': 'Contact Book\'s',
        'path': '/contactbookdirectory',
        'icon': <HiBookOpen size={21} />,
        'component': PageContactBookDirectory,
        'collapse': false,
        'items': [{
          'name': 'Contact Book\'s',
          'path': '/contactbookdirectory/contactbook',
          'icon': <HiNoSymbol size={15} />,
          'hide': true,
          'component': PageContactBookDirectoryContactBook
        }]
      },
      account?.accountType === 'TERMINATION' ? void 0 : {
        'name': 'DLT Manager',
        'path': '/dlt',
        'collapse': false,
        'component': PageDltDirectory,
        'icon': <HiBuildingStorefront size={21} />,
        'items': [{
          'name': 'Dlt Manager',
          'path': '/dlt/dlt',
          'hide': true,
          'icon': <HiNoSymbol size={15} />,
          'component': PageDltDirectoryDlt
        }]
      },
      account?.accountType === 'TERMINATION' ? {
        'name': 'Vendor Report',
        'path': '/',
        'icon': <HiChartBarSquare size={21} />,
        'collapse': false,
        'component': PageVendorReport,
        'items': [
          {
            'name': 'Vendor Report',
            'path': '/report',
            'icon': <HiChartBarSquare size={21} />,
            'collapse': false,
            'component': PageVendorReport
          }
        ]
      } : void 0,
    ])

  // Hook assignment.
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false)
  const [navHeight, setNavHeight] = React.useState(99)
  const [MutationAccountLogout] = useMutation(AccountLogoutMutation, { 'context': { 'headers': { 'l-authorization': account.token } }, 'client': _ApolloClientInstance_.current })
  const [QueryCustomerRead] = useLazyQuery(CustomerReadQuery, {
    'context': {
      'headers': {
        'l-authorization': account.token
      }
    },
    'client': _ApolloClientInstance_.current
  })
  const _QueryNotifyRead = useQuery(NotifyReadQuery, {
    'variables': {
      'customerId': customer.id
    },
    'context': {
      'headers': {
        'l-authorization': account.token
      }
    },
    'client': _ApolloClientInstance_.current,
    'fetchPolicy': Object.React.App.fetchPolicy,
    'pollInterval': Object.React.App.pollInterval
  })
  const _QueryAccountRead = useQuery(AccountReadQuery, {
    'context': {
      'headers': {
        'l-authorization': account.token
      }
    },
    'client': _ApolloClientInstance_.current,
    'fetchPolicy': Object.React.App.fetchPolicy,
    'pollInterval': Object.React.App.pollInterval
  })
  const _QueryRouteRead = useQuery(RouteReadQuery, {
    'variables': {
      'customerId': customer?.id,
      'skip': 0,
      'take': 10
    },
    'client': _ApolloClientInstance_.current,
    'fetchPolicy': Object.React.App.fetchPolicy,
    'pollInterval': Object.React.App.pollInterval
  })
  const _locationRef = useLocation()
  const _useNavigateRef = useNavigate()
  const _themeRef = useTheme()
  const _routeRef = React.useRef(_.compact(_.flatten([..._routes, ..._.compact(_routes).map(i => i.items)])))
  const _navBarHeightRef = React.useRef({ 'clientHeight': '99' })

  // Object assignment.
  const _Logout = () => MutationAccountLogout().then(() => AccountLogout()).catch(error => { throw error })
  const _GetActiveRoute = routes => {
    // Local variable.
    let i, n

    // Object assignment.
    const _ActiveRoute = o => _locationRef.pathname.split('/')[2]?.toLowerCase?.() === o?.split('/')?.[2]?.toLowerCase?.()
    const _NonCollapsibleActiveRoute = o => _locationRef.pathname === o

    // Loop through routes.
    for (i = 0; i < routes?.length; i++) {
      // If route is collapsible.
      if (routes[i]?.collapse) {
        // Loop through items.
        for (n = 0; n < routes[i]?.items.length; n++) if (_ActiveRoute(routes[i]?.items[n]?.path)) { return `${routes[i]?.items[n]?.name}` }
      } else if (!routes[i]?.collapse) {
        // If route is not collapsible.
        if (_NonCollapsibleActiveRoute(routes[i]?.path)) return routes[i]?.name
        if (_ActiveRoute(routes[i]?.path)) { return `${routes[i]?.name}` }

      }
    }

    // Return active route.
    return 'Page'
  }
  const _GetActiveNavBar = routes => {
    // Local variable.
    let i

    // Const assignment.
    const _activeNavBar = false

    // Loop through routes.
    for (i = 0; i < routes.length; i++) {
      // If route is collapsible.
      if (routes[i].collapse) {
        // Const assignment.
        const _collapseActiveNavbar = _GetActiveNavBar(routes[i].items)

        // If route is collapsible.
        if (_collapseActiveNavbar !== _activeNavBar) return _collapseActiveNavbar
      } else if (routes[i].category) {
        // Const assignment.
        const _collapseActiveNavbar = _GetActiveNavBar(routes[i].items)

        // If route is category.
        if (_collapseActiveNavbar !== _activeNavBar) return _collapseActiveNavbar
      } else if (-1 !== window.location.href.indexOf(routes[i].layout + routes[i].path)) {
        // If route is not collapsible.
        return routes[i].secondary
      }
    }

    // Return active navbar.
    return _activeNavBar
  }
  const _GetActiveNavBarText = routes => {
    // Local variable.
    let i

    // Const assignment.
    const _activeNavBar = false

    // Loop through routes.
    for (i = 0; i < routes.length; i++) {
      // If route is collapsible.
      if (routes[i].collapse) {
        // Const assignment.
        const _collapseActiveNavbar = _GetActiveNavBarText(routes[i].items)

        // If route is collapsible.
        if (_collapseActiveNavbar !== _activeNavBar) return _collapseActiveNavbar
      } else if (routes[i].category) {
        // Const assignment.
        const _categoryActiveNavBar = _GetActiveNavBarText(routes[i].items)

        // If route is category.
        if (_categoryActiveNavBar !== _activeNavBar) return _categoryActiveNavBar
      } else if (-1 !== window.location.href.indexOf(routes[i].layout + routes[i].path)) {
        // If route is not collapsible.
        return routes[i].messageNavbar
      }
    }

    // Return active navbar.
    return _activeNavBar
  }

  // Event listener.
  useSubscription(AccountReadSubscription, {
    'client': _ApolloClientInstance_.current,
    'onData': ({ data, error }) => {
      // If error exists then report failure.
      if (error instanceof Error) return error

      /*
       * If account details are available.
       * Then update account details.
       */
      if (!_.isEmpty(data?.data?.AccountRead) && !data?.data?.AccountRead.ban) {
        // Const assignment.
        const _dataToUpdate = {}

        // Update individual data to the context.
        if (!_.isEmpty(data?.data?.AccountRead.email)) Object.assign(_dataToUpdate, { 'email': data?.data?.AccountRead.email })
        if (!_.isEmpty(data?.displayName)) Object.assign(_dataToUpdate, { 'displayName': data?.displayName })
        if (!_.isEmpty(data?.bio)) Object.assign(_dataToUpdate, { 'bio': data?.bio })
        if (!_.isEmpty(data?.thumbnailStoredAt)) Object.assign(_dataToUpdate, { 'thumbnailStoredAt': data?.thumbnailStoredAt.path })
        if (!_.isEmpty(data?.profileCoverThumbnailStoredAt)) Object.assign(_dataToUpdate, { 'profileCoverThumbnailStoredAt': data?.profileCoverThumbnailStoredAt.path })

        // Update store with new information.
        AccountUpdate(_dataToUpdate)
      } else if (!_.isEmpty(data?.data?.AccountRead) && data?.data?.AccountRead.ban) {
        // Logout given user.
        AccountLogout()
      }

      // Return void 0.
      return void 0
    }
  })
  React.useEffect(() => {
    // If account type is termination and route is not vendor/report
    // then navigate to it and also make sure that current page is not login.
    if ('TERMINATION' === account?.accountType && '/vendor/report' !== _locationRef.pathname && '/login' !== _locationRef.pathname) _useNavigateRef('/vendor/report')
  }, [account, _locationRef])
  React.useEffect(() => {
    // Const assignment.
    const _accountReadQuery = _QueryAccountRead.data?.data?.AccountRead

    /*
     * Only update account details if they
     * are available.
     */
    if ('READ_SUCCESSFUL' === _.first([_accountReadQuery ?? {}]).status && !_.isEmpty(_accountReadQuery) && !_accountReadQuery.ban) {
      // Update account.
      AccountUpdate({
        'id': _.first([_accountReadQuery ?? {}]).id,
        'email': _.first([_accountReadQuery ?? {}]).email,
        'displayName': _.first([_accountReadQuery ?? {}]).displayName,
        'bio': _.first([_accountReadQuery ?? {}]).bio,
        'thumbnailStoredAt': _.first([_accountReadQuery ?? {}]).thumbnailStoredAt?.path,
        'profileCoverThumbnailStoredAt': _.first([_accountReadQuery ?? {}]).profileCoverThumbnailStoredAt?.path
      })
    } else if ((!_.isEmpty(_accountReadQuery) && _accountReadQuery.ban)) {
      /*
       * Logout immediately and remove
       * all credentials.
       */
      _Logout().catch(e => {
        // Report failure.
        throw e
      })
    }
  }, [])
  React.useEffect(() => {
    // Update apollo client instance.
    _ApolloClientInstance_.current = ApolloClientProvider(account, { ClearEverything })

    // If user is not logged in then redirect to login page.
    if ('/login' !== _locationRef.pathname && account && !account.isUserLoggedIn) _useNavigateRef('/login')
  }, [account])
  React.useEffect(() => {
    // Start NProgress when the document is ready
    NProgress.configure({ 'showSpinner': false })
    NProgress.start()

    // Clear timeout after sometime.
    const j = setTimeout(() => {
      // Stop NProgress after sometime
      NProgress.done()

      // Clear timeout.
      clearTimeout(j)

      // Update navHeight when ever it is available.
      setNavHeight(parseInt(_navBarHeightRef?.current?.clientHeight))
    }, 1000)
  })
  React.useEffect(() => {
    // Update navHeight when ever it is available.
    setNavHeight(parseInt(_navBarHeightRef?.current?.clientHeight))
  }, [_navBarHeightRef])
  React.useEffect(() => {
    // Async handler.
    const _Async = async () => {
      // Fetch customer details if account detail
      // are available.
      if (!_.isEmpty(account) && ['RETAIL', 'RESELLER']?.includes(account?.accountType)) {
        // Fetch customer details.
        const _QueryCustomerRead = await QueryCustomerRead({ 'variables': { 'customerId': account?.CustomerLogin?.[0]?.id } })

        // If customer details are available then update account details.
        if ('READ_SUCCESSFUL' === _.first(_QueryCustomerRead?.data?.CustomerRead)?.status) {
          // Update store with information.
          CustomerUpdate(_.first(_QueryCustomerRead?.data?.CustomerRead))
          RouteUpdate(_.compact(_.flatten(_.pluck(_.first(_QueryCustomerRead?.data?.CustomerRead)?.CustomerAccount, 'Route'))))
        }
      }
    }; _Async().catch(i => { throw i })
  }, [account])
  // Event handler.
  React.useEffect(() => {
    // Async handler.
    const _Async = async () => {
      // Make sure query route read is not empty.
      if (_QueryRouteRead?.data && _QueryRouteRead?.data?.RouteRead) RouteUpdate(_.first(_QueryRouteRead?.data?.RouteRead))
    }; _Async()
  }, [_QueryRouteRead?.data])

  // Return component.
  return (
    <SideBarNavigationContext.Provider value={[isDrawerOpen, setIsDrawerOpen]}>
      <ApolloProvider client={_ApolloClientInstance_.current}>
        <Routes>
          {_routeRef.current.map(i => {
            // Const assignment.
            const Component = __props => {
              // Const assignment.
              const Component = i.component

              // Return component.
              return i.noLayout ? <Component {...__props} /> : (
                <>
                  {_QueryNotifyRead?.data?.NotifyRead?.status === 'READ_SUCCESSFUL' && _.isEmpty(vendor.id) && (<Text className='notify'>{_QueryNotifyRead?.data?.NotifyRead?.message ?? 'hello'}</Text>)}
                  <SideBarNavigation routes={_routes} display='none' {...props} />
                  <Box
                    float='right'
                    bg='#F5F8FD'
                    display='flex'
                    flexDirection='column'
                    position='relative'
                    minH='inherit'
                    overflowY={_letUIOverflowFollowingPages?.includes(i.path) ? void 0 : 'scroll'}
                    w={{ 'base': '100%', 'xl': '80%' }}
                    height='inherit'
                    maxWidth={{ 'base': '100%', 'xl': '80%' }}
                    transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
                    transitionDuration='.2s, .2s, .35s'
                    transitionProperty='top, bottom, width'
                    transitionTimingFunction='linear, linear, ease'
                    overflowX='hidden'>
                    <Box
                      py='16px'
                      px='22px'
                      zIndex={100}
                      w={{
                        'base': '100%',
                        'md': `${'/' === i.path ? 'calc(100% / 1.0)' : '100%'}`,
                        'lg': `${'/' === i.path ? 'calc(100% / 1.0)' : '100%'}`,
                        'xl': `${'/' === i.path ? 'calc(100% / 1.25)' : '100%'}`
                      }}
                      position={{
                        'base': `${'/' === i.path ? 'absolute' : 'relative'}`,
                        'md': `${'/' === i.path ? 'fixed' : 'relative'}`
                      }} pb='0'>
                      <NavigationBar
                        ref={_navBarHeightRef}
                        logoText={''}
                        timelineChildren={(
                          <SimpleGrid
                            w='calc(100% - 25px)'
                            position='fixed'
                            transform='translateY(75px)'
                            columns={{ 'sm': 1, 'md': 2, 'lg': 2, 'xl': 4, '2xl': 4 }}
                            gap='22px'>
                            <TotalSmsSent />
                            <TotalSmsFailed />
                            <TotalSmsDelivered />
                            <DlrPercentage />
                          </SimpleGrid>
                        )}
                        brandText={_GetActiveRoute(_routeRef.current)}
                        secondary={_GetActiveNavBar(_routeRef.current)}
                        message={_GetActiveNavBarText(_routeRef.current)}
                        fixed={true}
                        {...props}
                      />
                    </Box>
                    <Box
                      flex={1}
                      p={{ 'base': '20px', 'md': '30px' }}
                      pe='20px'
                      bg='#F5F8FD'
                      flexDirection='column'
                      height={{ 'base': _letUIOverflowFollowingPages?.includes(i.path) ? 'auto' : `calc(100% - ${navHeight}px)` }}
                      maxHeight={_letUIOverflowFollowingPages?.includes(i.path) ? 'auto' : `calc(100% - ${navHeight}px)`}
                      boxSizing='border-box'
                      boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.1)'
                      display='flex'>
                      <Component {...__props} />
                    </Box>
                    <Toaster
                      position='bottom-left'
                      reverseOrder={false}
                      containerClassName='toaster'
                      toastOptions={{
                        'duration': 3000,
                        'style': {
                          'background': _themeRef.colors.purple[700],
                          'color': _themeRef.colors.white
                        }
                      }}
                    />
                  </Box>
                </>
              )
            }

            // Return component.
            return (
              <Route
                exact
                path={i.path}
                element={<Component />}
                key={String.random(10)}
              />
            )
          })}
          <Route path='*' element={Page404} />
        </Routes>
      </ApolloProvider>
    </SideBarNavigationContext.Provider>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'customer': PropTypes.object.isRequired,
  'account': PropTypes.object.isRequired,
  'AccountUpdate': PropTypes.func.isRequired,
  'AccountLogout': PropTypes.func.isRequired,
  'ClearEverything': PropTypes.func.isRequired,
  'CustomerUpdate': PropTypes.func.isRequired,
  'RouteUpdate': PropTypes.func.isRequired
}
Index.defaultProps = {}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'customer': __state.Customer, 'vendor': __state.Vendor, 'account': __state.Account, 'platform': __state.Platform })
const _MapDispatchToProps = __dispatch => ({
  'AccountUpdate': u => __dispatch({ 'type': 'ACCOUNT_UPDATE', 'Account': u }),
  'AccountLogout': () => __dispatch({ 'type': 'ACCOUNT_CLEAR' }),
  'CustomerUpdate': u => __dispatch({ 'type': 'CUSTOMER_UPDATE', 'Customer': u }),
  'RouteUpdate': u => __dispatch({ 'type': 'ROUTE_UPDATE', 'Route': u }),
  'ClearEverything': () => {
    // Clear all data.
    __dispatch({ 'type': 'ACCOUNT_CLEAR' })
    __dispatch({ 'type': 'PASS_ON_CLEAR' })
    __dispatch({ 'type': 'PLATFORM_CLEAR' })
    __dispatch({ 'type': 'CUSTOMER_CLEAR' })
    __dispatch({ 'type': 'VENDOR_CLEAR' })
    __dispatch({ 'type': 'ROUTE_CLEAR' })
  }
})


/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index)
