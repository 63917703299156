/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query CustomerReadQuery($customerOfCustomerId: ID, $take: PositiveInt, $skip: Int, $search: String!) {
  CustomerRead(customerOfCustomerId: $customerOfCustomerId, take: $take, skip: $skip, search: $search, returnDeliveriesOfTrunk: false) {
    id,
    createdAt,
    displayName,
    overDraftLimit,
    message,
    phone,
    rateEmail,
    status,
    country,
    supportEmail,
    type,
    _totalCount
    CustomerOf {
      id
    }
    CustomerOfCustomer {
      id
    }
    AccountManager {
      displayName
    }
    Company {
      referenceNumber
      displayName
      email
    }
    CustomerAccount {
      Rate {
          displayName
      }
      Route {
          displayName
      }
    }
    Credit {
      balance,
      limit,
      alertAmount
    }
    Billing {
      id
      alertAmount
      currency
      address
      period
      enableAutoInvoicing
      smsType
      email
    }
  }
}
`
