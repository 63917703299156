/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: prop-types library.
import _ from 'underscore' // Npm: underscore.js library.
import { useLazyQuery, useQuery } from '@apollo/client' // Npm: Apollo client.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { HiArrowUturnDown } from 'react-icons/hi2' // Npm: react-icons library.
import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useBreakpointValue
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import DateRangePicker from 'components/DateRangePicker'
import TableSpinner from 'components/TableSpinner'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'
import { MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import CustomerMarginReportQuery from './__query__/index.customerAccount.marginReport.query'
import CustomerAccountReadQuery from './__query__/index.customerAccount.read.query'


/*
 * STYLES
 */
import { cellStyle, headerStyle, rowStyle } from './index.style'


/*
 * OBJECTS
 */
const Index = ({ passOn }) => {
  // Const assignment.
  const _skipDifference = 8

  // Hook assignment.
  const [dateRange, setDateRange] = React.useState({
    'selection': {
      'startDate': Object.DateRangeProvider()?.startDate,
      'endDate': Object.DateRangeProvider()?.endDate,
      'key': 'selection'
    }
  })
  const [customerAccountId, setCustomerAccountId] = React.useState(void 0)
  const [smsRead, setSmsRead] = React.useState([])
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const [skipPage, setSkipPage] = React.useState(0)
  const [QueryCustomerAccountMarginReport, QueryCustomerAccountMarginReportResponse] = useLazyQuery(CustomerMarginReportQuery, {
    'variables': {
      'customerId': passOn?.customerId,
      'startDate': dateRange?.selection.startDate,
      'endDate': dateRange?.selection.endDate
    },
  })
  const _QueryCustomerAccountRead = useQuery(CustomerAccountReadQuery, { 'variables': { 'customerId': passOn?.customerId }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _tableHeaderHeightRef = React.useRef(0)
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  React.useEffect(() => {
    // Async handler.
    const _Async = async () => {
      // If id is empty then return.
      if (_.isEmpty(customerAccountId)) return void 0

      // Refetch data on date range change.
      const { data, error } = await QueryCustomerAccountMarginReport({
        'variables': {
          'customerAccountId': customerAccountId?.split('(')[1]?.split(')')[0],
          'startDate': dateRange.selection.startDate,
          'endDate': dateRange.selection.endDate
        }
      })

      // Report failure if error is caught.
      if (error instanceof Error) throw error

      // Const assignment.
      const _report = _.compact(Object.entries(data?.CustomerAccountMarginReport?._report ?? {}))

      // If data is not empty then update it.
      if (0 < _report?.length) {
        // Const assignment.
        const _totalsumup = {}

        // Merge all by country names.
        for (const [__, value] of _.compact(Object.values(_report))) {
          // Break by countryName.
          for (const [key, value2] of Object.entries(value)) {
            // If country name is not in the list then add it.
            if (!_totalsumup[key]) _totalsumup[key] = { 'totalsmscount': 0, 'revenue': 0, 'cost': 0, 'margin': 0 }

            // Update the values.
            _totalsumup[key].totalsmscount += value2?.totalsmscount
            _totalsumup[key].revenue += value2?.revenue
            _totalsumup[key].cost += value2?.cost
            _totalsumup[key].margin += value2?.margin
          }
        }

        // Update the state.
        setSmsRead(Object.entries(_totalsumup))
      }
    }; _Async().catch(i => { throw i })
  }, [dateRange, passOn, customerAccountId])

  // Return component.
  return (
    <>
      <Flex className='reportsCustomer base'>
        <Flex
          display='flex'
          flex={1}
          flexDirection='column'
          gap={_isCurrentViewMobile ? '12px' : '22px'}
          bg='white'
          height='100%'
          borderRadius='20px'
          p={_isCurrentViewMobile ? '12px' : '22px'}>
          <Flex pb='0px' justifyContent='space-between' alignItems='center'>
            <Flex flex={0.6} gap='12px' flexDir='column'>
              <Text as='h3' fontSize={17} fontWeight={500} display='flex' alignItems='center' color='brand.500' gap='6px'><HiArrowUturnDown />{_isCurrentViewMobile ? 'Filter Rows' : 'Filter Rows based on Date'}.</Text>
              <DateRangePicker
                startDate={dateRange?.selection?.startDate}
                endDate={dateRange?.selection?.endDate}
                w='100%'
                bg='purple.50'
                color='purple.600'
                fontSize='13px'
                height='max-content'
                gap='6px'
                setSelectDate={setDateRange}
              />
            </Flex>
            <Flex flex={0.4} justify='end'>
              <Flex gap='12px' flexDir='row' flexWrap='wrap'>
                <MemoizedSelect
                  w='240px'
                  name='customerId'
                  placeholder='Select Customer Account'
                  bg='purple.50'
                  color='purple'
                  data={customerAccountId}
                  options={(_QueryCustomerAccountRead?.data?.CustomerAccountRead ?? [])?.map(j => j.displayName ? `${j.displayName} (${j.id})` : void 0)}
                  onChange={e => setCustomerAccountId(e.target?.value)}
                />
              </Flex>
            </Flex>
          </Flex>
          <TableContainer
            flex={1}
            display='flex'
            borderRadius='15px'
            outline='1px solid #C5CFE8'>
            <Table variant='simple' size='md'>
              <Thead ref={_tableHeaderHeightRef}>
                <Tr
                  style={headerStyle}
                  position='sticky'
                  top={0}
                  zIndex='docked'>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    S.No.
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Country Name
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Total Count
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Revenue
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Cost
                  </Td>
                  <Td
                    style={rowStyle}>
                    Margin
                  </Td>
                </Tr>
              </Thead>
              {QueryCustomerAccountMarginReport.loading || QueryCustomerAccountMarginReportResponse?.loading ? (
                <TableSpinner
                  isLoading={true}
                  chopHeightFromHundredPercentage={
                    _tableHeaderHeightRef?.current?.clientHeight
                  }
                />
              ) : 0 === smsRead?.length || (0 < smsRead?.length && _.every(_.pluck(smsRead, 'status'), j => 'NO_SMS_FOUND' === j || 'CUSTOMERS_NOT_FOUND' === j)) ? (
                <TableSpinner
                  isLoading={false}
                  isEmpty={true}
                  chopHeightFromHundredPercentage={
                    _tableHeaderHeightRef?.current?.clientHeight
                  }
                />
              ) : (
                <Tbody style={cellStyle}>
                  {smsRead?.map(
                    (item, __index) => (
                      <Tr key={String.random(8)}>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {' '}
                          {`${__index + (skipDifference * skipPage) + 1}.`}
                        </Td>
                        <Td
                          width='100%'
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?.[0] ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?.[1]?.totalsmscount ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?.[1]?.revenue?.toFixed?.(2) ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?.[1]?.cost?.toFixed?.(2) ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}>
                          {item?.[1]?.margin?.toFixed?.(2) ?? 0}
                        </Td>
                      </Tr>
                    )
                  )}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </Flex>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.pluck(QueryCustomerAccountMarginReport?.data?.CustomerAccountMarginReport, '_totalCount')?.[0]}
        onPageChange={setSkipPage}
      />
    </>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
