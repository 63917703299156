/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import Debounce from 'lodash/debounce' // Npm: Lodash library.
import _ from 'underscore' // Npm: underscore.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useMutation, useQuery } from '@apollo/client' // Npm: Apollo client.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { IoIosSave } from 'react-icons/io' // Npm: React icons.
import {
  Button,
  Flex,
  Spinner
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import { MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import CustomerAttachRouteMutation from './__mutation__/index.customer.attachRoute.mutation'
import CustomerAttachRateMutation from './__mutation__/index.customer.attachRate.mutation'
import CustomerReadRateAndRouteQuery from './__query__/index.customer.readRateAndRoute.query'
import RouteReadQuery from './__query__/index.route.read.query'
import RateReadQuery from './__query__/index.rate.read.query'


/*
 * OBJECTS
 */
const Index = ({ onClose, account, passOn }) => {
  // Hook assignment.
  const [rate, setRate] = React.useState('')
  const [route, setRoute] = React.useState('')
  const [routeDisplayName, setRouteDisplayName] = React.useState('')
  const [rateDisplayName, setRateDisplayName] = React.useState('')
  const [MutationCustomerAttachRoute, MutationCustomerAttachRouteResponse] = useMutation(CustomerAttachRouteMutation)
  const [MutationCustomerAttachRate, MutationCustomerAttachRateResponse] = useMutation(CustomerAttachRateMutation)
  const _QueryCustomerReadRateAndRoute = useQuery(CustomerReadRateAndRouteQuery, { 'variables': { 'customerId': passOn?.customerId } })
  const _RateReadQuery = useQuery(RateReadQuery, { 'variables': { 'accountId': account?.id, 'type': 'RETAIL', 'search': rateDisplayName, 'take': 10000, 'skip': 0 }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _RouteReadQuery = useQuery(RouteReadQuery, { 'variables': { 'accountId': account?.id, 'type': 'RETAIL', 'search': routeDisplayName, 'take': 1000, 'skip': 0 }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })

  // Object assignment.
  const _AttachRateAndRouteToCustomer = async () => {
    // Local variable.
    let _MutationCustomerAttachRateToCustomer, _MutationCustomerAttachRouteToCustomer, _rate, _route

    // If route or rate contains (id) then extract the id.
    if (route?.includes?.('(') && route?.includes?.(')')) _route = route?.split('(')[1]?.split(')')[0]
    if (rate?.includes?.('(') && rate?.includes?.(')')) _rate = rate?.split('(')[1]?.split(')')[0]

    // Only Execute if route has data.
    if (!_.isEmpty(_route)) {
      // Execute mutation.
      _MutationCustomerAttachRouteToCustomer = await MutationCustomerAttachRoute({
        'variables': {
          'customerId': passOn?.customerId,
          'routeId': _route
        }
      })

      // If mutation caught an exception then report failure.
      if (_MutationCustomerAttachRouteToCustomer instanceof Error) {
        // Report failure.
        return _MutationCustomerAttachRouteToCustomer
      }

      // Style Guide.
      toast(_MutationCustomerAttachRouteToCustomer?.data?.CustomerAttachRoute?.message)
    }

    // Only proceed if rate has data.
    if (!_.isEmpty(_rate)) {
      // Bind Rate to given customer.
      _MutationCustomerAttachRateToCustomer = await MutationCustomerAttachRate({
        'variables': {
          'customerId': passOn?.customerId,
          'rateId': _rate
        }
      })

      // If mutation caught an exception then report failure.
      if (_MutationCustomerAttachRateToCustomer instanceof Error) {
        // Report failure.
        return _MutationCustomerAttachRateToCustomer
      }

      // Style Guide.
      toast(_MutationCustomerAttachRateToCustomer?.data?.CustomerAttachRate?.message)
    }

    // Report failure if both route and rate are empty.
    if (!_.isEmpty(_MutationCustomerAttachRouteToCustomer) && 'UPDATE_SUCCESSFUL' === _MutationCustomerAttachRouteToCustomer?.data?.CustomerAttachRoute?.status) return onClose?.()
    if (!_.isEmpty(_MutationCustomerAttachRateToCustomer) && 'UPDATE_SUCCESSFUL' === _MutationCustomerAttachRateToCustomer?.data?.CustomerAttachRate?.status) return onClose?.()

    // Style Guide.
    toast('Failed to attach the route and rate.')

    // Play toast on completion.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    // Update rate and route if data is available.
    if ('READ_SUCCESSFUL' === _QueryCustomerReadRateAndRoute?.data?.CustomerReadRateAndRoute?.status) {
      // Const assignment.
      const _rate = _.first(_QueryCustomerReadRateAndRoute?.data?.CustomerReadRateAndRoute?.CustomerAccount)?.Rate

      // Update rate and route.
      !_.isEmpty(_rate) && setRate(`${_rate?.displayName} (${_rate?.id})`)

      // Const assignment.
      const _route = _.first(_QueryCustomerReadRateAndRoute?.data?.CustomerReadRateAndRoute?.CustomerAccount)?.Route

      console.log(_route)
      // Update rate and route.
      !_.isEmpty(_route) && setRoute(`${_route?.displayName} (${_route?.id})`)
    }
  }, [_QueryCustomerReadRateAndRoute])

  // Return component.
  return (
    <>
      <Flex w='100%' className=' attachRouteAndRate' direction='column' gap='22px' key={String.random(9)}>
        <MemoizedSelect
          label='Assign Rate'
          defaultValue={rate}
          placeholder='e.g. "Awesome Rate"'
          onChange={e => setRate(e.target.value)}
          options={_.compact(_RateReadQuery?.data?.RateRead?.map(({ id, displayName }) => id && displayName ? `${displayName} (${id})` : void 0))}
        />
        <MemoizedSelect
          label='Assign Route'
          defaultValue={route}
          placeholder='e.g. "Amazing Route"'
          onChange={e => setRoute(e.target.value)}
          options={_.compact(_RouteReadQuery?.data?.RouteRead?.map(({ id, displayName }) => id && displayName ? `${displayName} (${id})` : void 0))}
        />
      </Flex >
      <Button
        width='100%'
        height='40px'
        mt='22px'
        borderRadius='10px'
        bg='rgba(117, 81, 255, 1)'
        color='rgba(255, 255, 255, 1)'
        _hover={{ 'bg': 'rgba(117, 81, 255, 1)' }}
        disabled={_.isEmpty(rate) && _.isEmpty(route)}
        onClick={_AttachRateAndRouteToCustomer}>
        Save Setting {MutationCustomerAttachRouteResponse.loading || MutationCustomerAttachRateResponse.loading ? <Spinner color='#c1a9f4' size='sm' ml='10px' /> : <IoIosSave size={16} style={{ 'marginLeft': '10px' }} />}
      </Button>
    </>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'onClose': PropTypes.func,
  'passOn': PropTypes.object,
  'account': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn, 'account': __state.Account })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
