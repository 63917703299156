/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query CustomerAccountReadQuery($customerAccountId: ID, $take: PositiveInt, $skip: Int) {
  CustomerAccountRead(customerAccountId: $customerAccountId, take: $take, skip: $skip) {
    status,
    id,
    Rate {
      id,
      displayName
    }
    Route {
      id,
      displayName
    }
  }
}
`
