/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query CustomerAccountReadQuery($customerAccountId: ID!) {
  CustomerAccountRead(customerAccountId: $customerAccountId) {
    Rate {
        id
      }
      Route {
        id
      }
  }
}
`
