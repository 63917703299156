/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation RoutePlanUpdateMutation($routePlanId: ID, $routePlanIds: [ID!], $input: [RoutePlanUpdateInput!]!) {
  RoutePlanUpdate(routePlanId: $routePlanId, routePlanIds: $routePlanIds, input: $input) {
    id,
    Mcc {
      id,
      mcc
    },
    Mnc {
      id,
      mnc
    }
    message
    status
  }
}
`
