/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query CustomerReadQuery($customerId: ID!) {
  CustomerRead(customerId: $customerId, returnDeliveriesOfTrunk: false) {
    type,
    country,
    supportEmail,
    overDraftLimit,
    rateEmail,
    phone,
    message,
    status
    Company {
      displayName,
      referenceNumber,
      email,
    }
    CustomerAccount {
      Rate {
        id
      }
      Route {
        id
      }
    }
    Billing {
      email,
      smsType,
      alertAmount,
      enableAutoInvoicing,
      period,
      currency,
      address
    }
    Credit {
      limit,
      balance,
      alertAmount

    }
  }
}
`
