/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for browser.
import TimePicker from 'react-time-picker' // Npm: Time picker for react.
import _ from 'underscore' // Npm: Underscore utility library.
import { useMutation } from '@apollo/client' // Npm: Apollo client for graphql.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { connect } from 'react-redux' // Npm: React redux for state management.
import { HiCheckBadge, HiChatBubbleOvalLeftEllipsis, HiClock, HiMinusCircle } from 'react-icons/hi2' // Npm: React icons.
import {
  Flex,
  Text,
  Textarea
} from '@chakra-ui/react' // Npm: Chakra UI component library.


/*
 * PACKAGES
 */
import Card from 'components/Card'
import SubmitButton from 'components/SubmitButton'
import FileUpload from 'components/FileUpload'
import ContactBookDirectorySelector from 'components/ContactBookDirectorySelector'
import DltDirectorySelector from 'components/DltDirectorySelector'
import { MemoizedInput, MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import SmsSendMutation from './__mutation__/index.sms.send.mutation'


/*
 * STYLES
 */
import './index.css'


/*
 * OBJECTS
 */
const Index = ({ customer }) => {
  // Hook assignment.
  const [forceReRender, setForceReRender] = React.useState(void 0)
  const [error, setError] = React.useState('')
  const [message, setMessage] = React.useState('')
  const [dpValues, setDPValues] = React.useState(void 0)
  const [dpVariables, setDPVariables] = React.useState(void 0)
  const [disableSourceAddress, setDisableSourceAddress] = React.useState(false)
  const [sentSuccessful, setSentSuccessful] = React.useState(false)
  const [messageType, setSetMessageType] = React.useState('PROMOTIONAL')
  const [encoding, setEncoding] = React.useState('TEXT')
  const [MutationSmsSend, MutationSmsSendResponse] = useMutation(SmsSendMutation)
  const _formDataRef = React.useRef({ 'customerAccountId': _.first(customer?.CustomerAccount)?.id })
  const _numbersCameThroughContactBook = React.useRef([])
  const _numbersCameThroughCsv = React.useRef([])
  const _numbersCameThroughDpu = React.useRef([])

  // Const assignment.
  const _isInputDisabled = MutationSmsSendResponse.loading
  const _sanitizedMessage = message?.toString?.()?.trim?.()
  const _messageLength = (160 < _sanitizedMessage?.length ? _sanitizedMessage?.length - 1 : _sanitizedMessage?.length) ?? 0
  const _isUnicodeString = (/[\u0080-\uffff]/u).test(_sanitizedMessage)
  const _messageChunkSize = Math.ceil((_messageLength ?? 0) / (_isUnicodeString ? 70 : 160))

  // Object assignment.
  const _AddScheduledDeliveryAt = i => {
    // If no time provided then report
    // failure.
    if (_.isEmpty(i)) return void 0

    // Create a Date object from the date string
    let date = new Date()

    // Split the time string into hours and minutes
    let [hours, minutes] = i.split(':').map(Number)

    // Set the hours and minutes on the Date object
    date.setHours(hours)
    date.setMinutes(minutes)
    date.setSeconds(0)

    // Update form data.
    _formDataRef.current.scheduledDeliveryAt = date

    // Force re-render.
    setForceReRender(String.random(6))
  }
  const _SenderIdPicker = i => {
    // Update form data.
    _formDataRef.current.sourceAddress = _.first(i.raw)?.senderId
    _formDataRef.current.templateId = _.first(i.raw)?.templateId
    _formDataRef.current.principalId = i.rawDlt?.peId
    _formDataRef.current.dlt = i.dlt
    _formDataRef.current.dltDirectory = i.dltDirectory

    // Force re-render.
    setMessage(_.first(i.raw)?.content)
    setDisableSourceAddress(!_.isEmpty(i.dlt))
    setForceReRender(String.random(7))

    // Return void.
    return void 0
  }
  const _ContactBookIdPicker = i => {
    // Only update mnc if it is available.
    if (i.contactBook) i.contactBook = i.contactBook?.map?.(e => e?.includes('(') && e?.includes(')') ? e?.split?.('(')?.[1]?.split?.(')')?.[0] : e)

    // Update form data.
    _formDataRef.current = ({
      ..._formDataRef.current,
      'contactBookDirectoryId': i.contactBookDirectory?.includes?.('(') && i.contactBookDirectory?.includes?.(')') ? i.contactBookDirectory?.split?.('(')?.[1]?.split?.(')')?.[0] : i.contactBookDirectory,
      'contactBook': _.uniq(i.contactBook)
    })

    // Post message to worker.
    return void 0
  }
  const _onDynamicParameterUpload = ({ columns, rows }) => {
    // If no phone numbers are found then return void.
    if (_.isEmpty(rows)) return void 0

    // Update _numbersCameThroughDpu.
    _numbersCameThroughDpu.current = rows

    // Update variables if they are present.
    setDPVariables(columns)

    // Update dbValues if they are present.
    setDPValues(rows)
  }
  const _onDynamicParameterClear = () => {
    // Remove all phonNumbers added through dpu.
    _numbersCameThroughDpu.current = []

    // Clear all variables.
    setDPValues(void 0)
    setDPVariables(void 0)
  }
  const _PhoneNumberUploadThroughCsv = ({ rows }, __onClear) => {
    // If on clear then clear destination address.
    if (__onClear) {
      // Flatten _numbersCameThroughCsv.current before removing.
      _numbersCameThroughCsv.current = []

      // Force re-render.
      setForceReRender(String.random(8))

      // Return void.
      return void 0
    }

    // Only proceed if file is available.
    if (_.isEmpty(rows)) return void 0

    // Flatten all rows.
    rows = _.compact(_.flatten(rows))

    // update _numbersCameThroughCsv
    _numbersCameThroughCsv.current = rows

    // Force update the ui.
    setForceReRender(String.random(6))
  }
  const _SubmitForm = async e => {
    // Local variable.
    let _messages, _destinationAddress

    // Variable assignment.
    _messages = []
    _destinationAddress = _.isEmpty(_formDataRef?.data?.destinationAddress) ? void 0 : _formDataRef?.data?.destinationAddress

    // Prevent default behavior.
    e.preventDefault()

    // Clear error.
    setError('')

    // Update encoding if it is not available.
    if (_.isEmpty(_formDataRef.current.encoding)) _formDataRef.current.encoding = _isUnicodeString ? 'TEXT' : 'UNICODE'

    // If dpValues are available then update messages.
    if (!_.isEmpty(dpValues)) {
      // Update message.
      _messages = Array.from({ 'length': dpValues.length }, (__, i) => {
        // Local variable.
        let _message

        // Variable assignment.
        _message = message

        // Loop over dpVariables.
        for (const dpVariable in dpVariables) {
          // Replace dpVariable with actual value.
          _message = _message.replace(`@${dpVariables[dpVariable]}`, dpValues[i][dpVariable])
        }

        // Return message.
        return _message
      });

      // Update destination address.
      _destinationAddress = _.compact(Array.from({ 'length': dpValues.length }, (__, i) => dpValues[i][0]))
    }

    // If _numbersCameThroughCsv.current is available then update.
    if (!_.isEmpty(_numbersCameThroughCsv.current) && _.isArray(_numbersCameThroughCsv.current)) _destinationAddress = _numbersCameThroughCsv.current

    // If dpValues are empty and message on forms
    // has content then update it.
    if (!_.isEmpty(message) && _.isEmpty(_messages)) _messages = [message]

    // If phone number is available then add it.
    if (!_.isEmpty(_formDataRef.current.destinationAddress)) _destinationAddress = _formDataRef.current.destinationAddress

    // Make sure that customer account id exists.
    if (_.isEmpty(_formDataRef.current.customerAccountId)) _formDataRef.current.customerAccountId = _.first(customer?.Customer?.CustomerAccount)?.id

    // Update _formDataRef.current with message.
    _messages = _.compact(_messages)
    _formDataRef.current.type = messageType
    _formDataRef.current.encoding = disableSourceAddress ? (_isUnicodeString ? 'UNICODE' : 'TEXT') : encoding

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'destinationAddress': JoiBrowser.array().items(JoiBrowser.string().optional()).optional(),
      'sourceAddress': JoiBrowser.string().required(),
      'type': JoiBrowser.string().required(),
      'encoding': JoiBrowser.string().required(),
      'esmClass': JoiBrowser.number().optional(),
      'priorityFlag': JoiBrowser.number().optional(),
      'message': JoiBrowser.array().items(JoiBrowser.string().required()).required(),
      'scheduledDeliveryAt': JoiBrowser.date().optional(),
      'repeatDeliveryAt': JoiBrowser.string().optional(),
      'principalId': JoiBrowser.string().optional(),
      'templateId': JoiBrowser.string().optional(),
      'contactBookDirectoryId': JoiBrowser.string().optional(),
      'contactBook': JoiBrowser.array().items(JoiBrowser.string().optional()).optional()
    }).options({ 'allowUnknown': true })

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate({ ..._formDataRef.current, 'customerAccountId': _.first(customer?.CustomerAccount)?.id, 'message': _messages, 'destinationAddress': _destinationAddress ?? [] })

    // If error exists then report failure.
    if (_.isEmpty(_destinationAddress) && _.isEmpty(_formDataRef?.current?.contactBook) && _.isEmpty(_formDataRef?.current?.contactBookDirectoryId)) return setError('Please provide destinationAddress')
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)
    if (!_formDataRef.current.customerAccountId) {
      // Show modal if customerAccountId is not attached
      toast('CustomerAccountId is lost. kindly logout and login again.')

      // Return void.
      return void 0
    }

    // Execute update mutation.
    const _MutationSmsSend = await MutationSmsSend({ 'variables': { ..._formDataRef.current, 'message': _messages, 'destinationAddress': _destinationAddress ?? [] } })

    // If mutation caught an exception then report failure.
    if (_MutationSmsSend instanceof Error) {
      // Show modal if there's an error
      setError(_MutationSmsSend.message)

      // Return void.
      return void 0
    }

    // Style Guide.
    toast(_MutationSmsSend?.data?.SmsSend?.message)

    // Execute onClose if response is successful.
    if ('QUEUED_SUCCESSFUL' === _MutationSmsSend?.data?.SmsSend?.status) {
      // Set successful message.
      setError('')
      setSentSuccessful(true)

      // Set sent successfully
      const j = setTimeout(() => { setSentSuccessful(false); clearTimeout(j) }, 2000)
    }

    // Report void 0.
    return void 0
  }

  // Make sure to disable input if other options are chosen.
  const _isDestinationAddressInputDisabled = _numbersCameThroughContactBook.current.length > 0 || _numbersCameThroughCsv.current.length > 0
  const _totalDestinationAddress = _numbersCameThroughContactBook.current.length || _formDataRef?.current?.destinationAddress?.length

  // Return Component.
  return (
    <form onSubmit={_SubmitForm} className='sendMessage'>
      <Card
        flexDirection='column'
        gap='1rem'
        p='0'
        justifyContent='space-between'>
        <Flex flexDir='column' w='100%' overflow='auto'>
          <Flex p='22px' py='11px' flexDir='column'>
            <Flex flexDir='column' my='24px' mb='0' color='brand.500'>
              <Flex flexDir='row' gap='6px' alignItems='center'>
                <HiChatBubbleOvalLeftEllipsis fontSize='31px' />
                <Text color='black' fontSize='3xl' className='highlightHeadline'>Send Sms</Text>
              </Flex>
              <Text w='70%' color='gray.500' fontSize='lg' mt='12px'>You have two options one can send one message at one time and another can send multiple at one time.</Text>
            </Flex>
          </Flex>
          <Flex p='22px' flexDirection='column' w='100%' gap='12px'>
            <Flex border='1px solid var(--chakra-colors-brand-500)' p='22px' borderRadius='12px' bg='brand.100' flexDir='column' gap='12px'>
              <Text mb='22px' fontSize='lg' w='70%'>You can choose one of the following methods for uploading phone numbers, or any combination you want.</Text>
              <ContactBookDirectorySelector
                color='white'
                contactBookDirectoryValue={_formDataRef?.current?.contactBookDirectoryId}
                contactBookValue={_formDataRef?.current?.contactBook}
                inValidPhoneNumbero={error?.includes('destinationAddress')}
                inValidPhoneNumberoDirectory={error?.includes('destinationAddress')}
                bg='brand.500'
                onChange={_ContactBookIdPicker}
                disabled={_isInputDisabled}
              />
              <Flex my='12px' flexDir='column'>
                <Text fontWeight={500} mb='6px'>Upload Numbers through csv ?</Text>
                <FileUpload
                  bg='brand.500'
                  label='e.g. Special Numbers.csv'
                  color={'ENTRY_EMPTY' === error ? 'red' : 'white'}
                  onChange={r => _PhoneNumberUploadThroughCsv(r, false)}
                  onClear={r => _PhoneNumberUploadThroughCsv(r, true)} />
                {_numbersCameThroughCsv.current.length === 0 ? void 0 : <Text py='6px' fontWeight={500} color='brand.500'>Numbers loaded : {_numbersCameThroughCsv.current.length}</Text>}
              </Flex>
              <MemoizedInput
                h='100px'
                disabled={_isDestinationAddressInputDisabled}
                name='destinationAddress'
                borderRadius='16px'
                color='white'
                labelColor='black'
                label='Mobile Number (Separated by , )'
                bg='brand.500'
                data={_.uniq(_.compact(_formDataRef.current.destinationAddress))}
                onChange={e => {
                  // Const assignment.
                  const { name, value } = e.target

                  // Update form data.
                  _formDataRef.current = ({
                    ..._formDataRef.current,
                    [name]: value
                  })
                }}
                placeholder='Enter Mobile Number'
                isInvalid={error?.includes('destinationAddress')}
                error={error}
                separators={[',', 'Enter', 'Tab']}
                isRequired={true}
                isMultiple={true}
              />
              {_totalDestinationAddress > 0 ? (<Text fontWeight={700}>Total : <Text as='span' color='brand.500'>{_totalDestinationAddress}</Text> Phone number's loaded.</Text>) : void 0}
            </Flex>
            <Flex border='1px solid var(--chakra-colors-green-500)' p='22px' borderRadius='12px' bg='green.50' flexDir='column' gap='12px'>
              <Flex color='green.500' alignItems='center' gap='6px'>
                <HiCheckBadge fontSize='21px' />
                <Text fontSize='lg'>DLT Manager</Text>
              </Flex>
              <Text my='12px' fontSize='lg' w='70%'>You can choose one of the following methods for choosing sender Id, Or you can enter it manually.</Text>
              <Flex w='100%' flexDir='column' gap='22px'>
                <DltDirectorySelector
                  disabled={_isInputDisabled}
                  dltDirectoryValue={_formDataRef?.current?.dltDirectory}
                  dltValue={_formDataRef?.current?.dlt}
                  inValidDlt={error?.includes('dlt')}
                  inValidDltDirectory={error?.includes('dltDirectory')}
                  bg='green.500'
                  color='white'
                  onChange={_SenderIdPicker}
                />
                <MemoizedInput
                  name='sourceAddress'
                  w='100%'
                  disabled={disableSourceAddress || _isInputDisabled}
                  labelColor='black'
                  color={error?.includes('sourceAddress') ? 'black' : 'white'}
                  borderRadius='16px'
                  label='Open SID'
                  defaultValue={_formDataRef.current.sourceAddress}
                  isRequired={true}
                  onChange={e => {
                    // Const assignment.
                    const { name, value } = e.target

                    // Update form data.
                    _formDataRef.current = ({
                      ..._formDataRef.current,
                      [name]: value
                    })
                  }}
                  key={forceReRender}
                  bg={error?.includes('sourceAddress') ? 'white' : 'green.500'}
                  placeholder='Sender Id'
                  isInvalid={error?.includes('sourceAddress')}
                  error={error}
                />
              </Flex>
            </Flex>
            <Flex flexDir='row' justifyContent='space-between' my='12px' w='100%' gap='12px'>
              <MemoizedSelect
                name='type'
                disabled={_isInputDisabled}
                borderRadius='16px'
                label='Message Type'
                value={messageType}
                options={Object.React.App.enums.SMS_TYPE.enums.map(i => i.key)}
                onChange={e => setSetMessageType(e?.target?.value)}
                placeholder='Choose Message Type'
                isInvalid={error?.includes('type')}
                error={error}
                key={message}
              />
              <MemoizedSelect
                name='encoding'
                disabled={disableSourceAddress || _isInputDisabled}
                borderRadius='16px'
                label='Message Encoding'
                value={disableSourceAddress ? (_isUnicodeString ? 'UNICODE' : 'TEXT') : encoding}
                options={Object.React.App.enums.SMS_BUILT_TYPE.enums.map(i => i.key)}
                onChange={e => setEncoding(e?.target?.value)}
                placeholder='Message Encoding'
                isInvalid={error?.includes('encoding')}
                error={error}
              />
            </Flex>
            <Flex flexDirection='row' w='100%' gap='22px' justifyContent='space-between'>
              <MemoizedSelect
                name='esmClass'
                disabled={_isInputDisabled}
                borderRadius='16px'
                label='Esm Class'
                value={_formDataRef.current.esmClass}
                options={[
                  `0 (Default mode)`,
                  '1 (Datagram mode)',
                  `2 (Forward / Transaction mode)`,
                  `3 (Store and forward mode)`,
                  `64 (UDHI + Default mode)`,
                  `65 (UDHI + Datagram mode)`,
                  '66 (UDHI + Forward / Transaction mode)',
                  '67 (UDHI + Store and forward mode)'
                ]}
                onChange={e => {
                  // Const assignment.
                  const { name, value } = e.target

                  // Update form data.
                  _formDataRef.current = ({
                    ..._formDataRef.current,
                    [name]: parseInt(value.split(' ')[0], 10)
                  })
                }}
                placeholder='Select Esm Class'
                isInvalid={error?.includes('esmClass')}
                error={error}
              />
              <MemoizedSelect
                name='priorityFlag'
                disabled={_isInputDisabled}
                borderRadius='16px'
                label='Priority Flag'
                value={_formDataRef.current.priorityFlag}
                options={[1, 2, 3]}
                onChange={e => {
                  // Const assignment.
                  const { name, value } = e.target

                  // Update form data.
                  _formDataRef.current = ({
                    ..._formDataRef.current,
                    [name]: parseInt(value, 10)
                  })
                }}
                placeholder='Priority Flag'
                isInvalid={error?.includes('priorityFlag')}
                error={error}
              />
            </Flex>
            <Flex position='relative' flexDirection='column' w='100%'>
              <Text py='12px' fontWeight={500}>Short Message<Text as='span' color='tomato'>*</Text></Text>
              {dpVariables ? (
                <Flex pb='22px' flexDir='column'>
                  <Text pb='12px' fontWeight={500} color='tomato'>Variables Found&nbsp;&nbsp;</Text>
                  <Text pb='12px' fontWeight={500} fontSize='md' color='gray.500'>Kindly add following variables according to your need. e.g. "Hello, @Username"</Text>
                  <Flex flexDir='row' gap='12px'>
                    {dpVariables?.map?.((i) => (<Text fontSize='16px' fontWeight={500} color='gray.600'>@{i}</Text>))}
                  </Flex>
                </Flex>
              ) : void 0}
              <Textarea
                name='message'
                width='100%'
                p='22px'
                bg={error?.includes('message') ? 'white' : 'gray.100'}
                disabled={_isInputDisabled}
                resize='none'
                borderRadius='16px'
                label='Message'
                _placeholder={{ 'color': '#000' }}
                minH='220px'
                defaultValue={message}
                onChange={e => {
                  // Const assignment.
                  const { name, value } = e.target

                  // Update form data.
                  _formDataRef.current = ({
                    ..._formDataRef.current,
                    [name]: value
                  })

                  // Update message state.
                  setMessage(value)
                }}
                placeholder='Enter Message'
                isInvalid={error?.includes('message')}
                error={error}
                isRequired={true}
                type='textArea'
                key={forceReRender}
              />
              <Flex position='absolute' bottom='12px' right='12px' zIndex={10}>
                <FileUpload bg='gray.300' onClear={_onDynamicParameterClear} onChange={_onDynamicParameterUpload} label='e.g. "dynamic parameters.csv"' />
              </Flex>
            </Flex>
            <Flex align='center' gap='6px'>
              <Text>Encoding <Text as='span' fontWeight={700}>{_isUnicodeString ? 'Unicode' : 'Text'}</Text></Text>
              <Text>Message Parts <Text as='span' fontWeight={700}>{_messageChunkSize}</Text></Text>
              <Text>Total Length <Text as='span' fontWeight={700}>{_messageLength}</Text></Text>
            </Flex>
            <Flex mt='22px' flexDir='row' justifyContent='flex-end' alignItems='center'>
              <Flex flexDir='row' alignItems='flex-start' gap='22px'>
                <Flex flexDir='column' alignItems='flex-start' gap='6px'>
                  <TimePicker
                    onChange={_AddScheduledDeliveryAt}
                    value={_formDataRef.current.scheduledDeliveryAt}
                    disableClock
                    clearIcon={<HiMinusCircle />}
                    hourPlaceholder='HH'
                    minutePlaceholder='MM'
                    secondPlaceholder='SS'
                    format='h:m:s a'
                    maxDetail='minute' />
                  <Flex flexDir='column'>
                    <Flex alignItems='center' gap='6px'>
                      <Flex color='brand.500'><HiClock /></Flex>
                      <Text fontSize='md' fontWeight={500}>Schedule this Job ?</Text>
                    </Flex>
                    <Text as='span' color='gray.500' fontSize='sm'>(12 Hour time, GMT 0)</Text>
                  </Flex>
                </Flex>
              </Flex>
              <SubmitButton
                bg={sentSuccessful ? 'green.500' : 'brand.500'}
                color='white'
                isLoading={MutationSmsSendResponse.loading}
                defaultText='Send Sms'
                disabled={_isInputDisabled}
                onSubmit={_SubmitForm}
              />
            </Flex>
          </Flex>
        </Flex>
      </Card>
    </form >
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'customer': PropTypes.object.isRequired
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'customer': __state.Customer })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
