/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { useLazyQuery, useMutation, useQuery } from '@apollo/client' // Npm: Apollo client.
import { Flex } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import SubmitButton from 'components/SubmitButton'
import { MemoizedInput, MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import CustomerReadQuery from './__query__/index.customer.read.query'
import CustomerAccountReadQuery from './__query__/index.customerAccount.read.query'
import SmppReadServerOwnSmpp from './__query__/index.smpp.readServerOwnSmpp.query'
import CustomerUpdateMutation from './__mutation__/index.customer.update.mutation'
import CustomerCreateMutation from './__mutation__/index.customer.create.mutation'
import AccountRegisterWithEmailMutation from './__mutation__/index.account.create.mutation'
import CustomerAttachAccountLoginMutation from './__mutation__/index.customer.attachAccountLogin.mutation'
import CustomerAccountAttachSmppMutation from './__mutation__/index.customerAccount.attachSmpp.mutation'
import CustomerAccountCreateMutation from './__mutation__/index.customerAccount.create.mutation'
import CustomerAttachRateMutation from './__mutation__/index.customer.attachRate.mutation'
import CustomerAttachRouteMutation from './__mutation__/index.customer.attachRoute.mutation'


/*
 * OBJECTS
 */
const Index = ({ customer, isOpen, isCreateOnly, onClose, passOn }) => {
  // Hook assignment.
  const [error, setError] = React.useState('')
  const [forceReRender, setForceReRender] = React.useState('')
  const [MutationCustomerAccountAttachSmpp, MutationCustomerAccountAttachSmppResponse] = useMutation(CustomerAccountAttachSmppMutation)
  const [MutationCustomerAccountCreate, MutationCustomerAccountCreateResponse] = useMutation(CustomerAccountCreateMutation)
  const [QueryCustomerRead, QueryCustomerReadResponse] = useLazyQuery(CustomerReadQuery, { 'variables': { 'customerId': passOn?.customerId } })
  const [MutationCustomerAttachRoute, MutationCustomerAttachRouteResponse] = useMutation(CustomerAttachRouteMutation)
  const [MutationCustomerAttachRate, MutationCustomerAttachRateResponse] = useMutation(CustomerAttachRateMutation)
  const _QueryCustomerAccountRead = useQuery(CustomerAccountReadQuery, { 'variables': { 'customerAccountId': customer?.CustomerAccount?.[0]?.id }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const [MutationCustomerAttachAccountLogin] = useMutation(CustomerAttachAccountLoginMutation)
  const [MutationAccountRegisterWithEmail, MutationAccountRegisterWithEmailResponse] = useMutation(AccountRegisterWithEmailMutation)
  const [MutationCustomerCreate, MutationCustomerCreateResponse] = useMutation(CustomerCreateMutation)
  const [MutationCustomerUpdate, MutationCustomerUpdateResponse] = useMutation(CustomerUpdateMutation)
  const _QuerySmppReadServerOwnSmpp = useQuery(SmppReadServerOwnSmpp, { 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _formDataRef = React.useRef({})

  // Object assignment.
  const _SubmitForm = async e => {
    // Local variable.
    let _MutationAccountRegisterWithEmail

    // Prevent default behavior.
    e.preventDefault()

    // Reset error.
    setError('')

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'companyName': JoiBrowser.string().required(),
      'companyReferenceNumber': JoiBrowser.string().required(),
      'country': JoiBrowser.string().required(),
      'companyEmail': JoiBrowser.string().email().required(),
      'supportEmail': JoiBrowser.string().email().optional().allow(''),
      'billingEmail': JoiBrowser.array().items(JoiBrowser.string().email()).required(),
      'rateEmail': JoiBrowser.array().items(JoiBrowser.string().email()),
      'billingAddress': JoiBrowser.string().required(),
      'currency': JoiBrowser.string().required(),
      'billingPeriod': JoiBrowser.string().required(),
      'phone': JoiBrowser.string().required(),
      'smsType': JoiBrowser.string().optional(),
      'overDraftLimit': JoiBrowser.number().required(),
      'alertAmount': JoiBrowser.number().optional(),
      'enableAutoInvoicing': JoiBrowser.boolean().optional()
    }).options({ 'allowUnknown': true })

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    // Const assignment.
    const _rateId = _.first(_QueryCustomerAccountRead?.data?.CustomerAccountRead)?.Rate?.id
    const _routeId = _.first(_QueryCustomerAccountRead?.data?.CustomerAccountRead)?.Route?.id

    // Only proceed if parent customer has rate and route.
    // attached to it.
    if (_.isEmpty(_rateId) || _.isEmpty(_routeId)) {
      // Update error.
      setError('Rate and Route are required to proceed.')

      // Report failure.
      return toast('Rate and Route are required to proceed.')
    }

    /*
     * Update _formDataRef with customerId
     * if it doesn't exist and also make sure that mode
     * is not create only.
     */
    if (!isCreateOnly && _.isEmpty(_formDataRef.current?.customerId)) _formDataRef.current = { ..._formDataRef.current, 'customerId': passOn?.customerId }

    // Only create account on new customer creation.
    if (isCreateOnly) {
      // Execute account registration mutation.
      _MutationAccountRegisterWithEmail = await MutationAccountRegisterWithEmail({
        'variables': {
          'displayName': _formDataRef?.current?.companyName,
          'email': _formDataRef?.current?.companyEmail,
          'password': String.random(8),
          'accountType': 'RETAIL'
        }
      })

      // If mutation caught an exception then report failure.
      if (_MutationAccountRegisterWithEmail instanceof Error) return _MutationAccountRegisterWithEmail
      if ('ACCOUNT_WITH_EMAIL_FOUND' === _MutationAccountRegisterWithEmail?.data?.AccountRegisterWithEmail?.status) {
        // Report failure.
        setError('companyEmail already registered. Please try something else.')

        // Style Guide.
        return toast('Given company email is already registered. Please try something else.')
      }
      if ('ACCOUNT_DISPLAY_NAME_ALREADY_EXISTS' === _MutationAccountRegisterWithEmail?.data?.AccountRegisterWithEmail?.status) {
        // Report failure.
        setError('companyName already registered. Please try something else.')

        // Style Guide.
        return toast('Given company name is already registered. Please try something else.')
      }
      // On Successful response from the mutation.
      if ('CREATE_SUCCESSFUL' !== _MutationAccountRegisterWithEmail?.data?.AccountRegisterWithEmail?.status) return toast(_MutationAccountRegisterWithEmail?.data?.AccountRegisterWithEmail?.message)

      // Style Guide.
      toast(_MutationAccountRegisterWithEmail?.data?.AccountRegisterWithEmail?.message)
    }

    // Execute update mutation.
    const _MutationCustomerUpdate = await [isCreateOnly ? MutationCustomerCreate : MutationCustomerUpdate]?.[0]({ 'variables': { ..._.omit(_formDataRef?.current, isCreateOnly ? 'customerId' : void 0), 'type': 'RETAIL', 'customerOfCustomerId': customer?.id } })

    // If mutation caught an exception then report failure.
    if (_MutationCustomerUpdate instanceof Error) return _MutationCustomerUpdate

    // Style Guide.
    toast(_MutationCustomerUpdate?.data?.CustomerUpdate?.message || _MutationCustomerUpdate?.data?.CustomerCreate?.message)

    // Only execute onClose only if response is successful.
    if ('CREATE_SUCCESSFUL' === _MutationCustomerUpdate?.data?.CustomerCreate?.status) {
      // On Successful create attach given customer with given account login.
      const _MutationCustomerAttachAccountLogin = await MutationCustomerAttachAccountLogin({
        'variables': {
          'customerId': _MutationCustomerUpdate?.data?.CustomerCreate?.id,
          'accountId': _MutationAccountRegisterWithEmail?.data?.AccountRegisterWithEmail?.id
        }
      })

      // If mutation caught an exception then report failure.
      if (_MutationCustomerAttachAccountLogin instanceof Error) return _MutationCustomerAttachAccountLogin

      // Style Guide.
      toast(_MutationCustomerAttachAccountLogin?.data?.CustomerAttachAccountLogin?.message)

      // On Successful response from the mutation.
      if ('UPDATE_SUCCESSFUL' === _MutationCustomerAttachAccountLogin?.data?.CustomerAttachAccountLogin?.status) {
        /*
           * Create customer account with dummy name
           * so that smpp could be attached to it.
           */
        const _MutationCustomerAccountCreate = await MutationCustomerAccountCreate({
          'variables': {
            'customerId': _MutationCustomerUpdate?.data?.CustomerCreate?.id,
            'displayName': String.random(9)
          }
        })

        // If mutation caught an exception then report failure.
        if (_MutationCustomerAccountCreate instanceof Error) return _MutationCustomerAccountCreate

        // Style Guide.
        toast(_MutationCustomerAccountCreate?.data?.CustomerAccountCreate?.message)

        // Execute mutation.
        const _MutationCustomerAccountAttachSmpp = await MutationCustomerAccountAttachSmpp({
          'variables': {
            'customerAccountId': _MutationCustomerAccountCreate?.data?.CustomerAccountCreate?.id,
            'smppId': _QuerySmppReadServerOwnSmpp?.data?.SmppReadServerOwnSmpp?.id
          }
        })

        // If mutation caught an exception then report failure.
        if (_MutationCustomerAccountAttachSmpp instanceof Error) return _MutationCustomerAccountAttachSmpp

        // Style Guide.
        toast(_MutationCustomerAccountAttachSmpp?.data?.CustomerAccountAttachSmpp?.message)
      }

      // Attach route and rate to given customer.
      // Bind Rate to given customer.
      const _MutationCustomerAttachRateToCustomer = await MutationCustomerAttachRate({
        'variables': {
          'customerId': _MutationCustomerUpdate?.data?.CustomerCreate?.id,
          'rateId': _rateId
        }
      })

      // If mutation caught an exception then report failure.
      if (_MutationCustomerAttachRateToCustomer instanceof Error) return _MutationCustomerAttachRateToCustomer

      // Style Guide.
      toast(_MutationCustomerAttachRateToCustomer?.data?.CustomerAttachRate?.message)

      // Attach route and rate to given customer.
      // Bind Rate to given customer.
      const _MutationCustomerAttachRouteToCustomer = await MutationCustomerAttachRoute({
        'variables': {
          'customerId': _MutationCustomerUpdate?.data?.CustomerCreate?.id,
          'routeId': _routeId
        }
      })

      // If mutation caught an exception then report failure.
      if (_MutationCustomerAttachRouteToCustomer instanceof Error) return _MutationCustomerAttachRouteToCustomer

      // Style Guide.
      toast(_MutationCustomerAttachRouteToCustomer?.data?.CustomerAttachRoute?.message)

      // Execute onClose only if response is successful.
      return onClose?.()

    }
    if ('UPDATE_SUCCESSFUL' === _MutationCustomerUpdate?.data?.CustomerUpdate?.status || 'CREATE_SUCCESSFUL' === _MutationCustomerUpdate?.data?.CustomerCreate?.status) return onClose?.()

    // Return void.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    // _Async handler.
    const _Async = async () => {
      // Const assignment.
      const _QueryCustomerReadQuery = await QueryCustomerRead({ 'variables': { 'customerId': isCreateOnly ? 'UN_KNOWN' : passOn?.customerId } })

      // If query caught an exception then report failure.
      if (_QueryCustomerReadQuery instanceof Error) return _QueryCustomerReadQuery

      /*
       * If customer details fetch complete then
       * update its value.
       */
      if (_QueryCustomerReadQuery?.data?.CustomerRead) {
        // Update form data.
        _formDataRef.current = {
          'customerId': passOn?.customerId,
          'type': _.first(_QueryCustomerReadQuery?.data?.CustomerRead)?.type,
          'companyReferenceNumber': _.first(_QueryCustomerReadQuery?.data?.CustomerRead)?.Company?.referenceNumber,
          'companyName': _.first(_QueryCustomerReadQuery?.data?.CustomerRead)?.Company?.displayName,
          'companyEmail': _.first(_QueryCustomerReadQuery?.data?.CustomerRead)?.Company?.email,
          'supportEmail': _.first(_QueryCustomerReadQuery?.data?.CustomerRead)?.supportEmail,
          'rateEmail': _.first(_QueryCustomerReadQuery?.data?.CustomerRead)?.rateEmail,
          'billingEmail': _.first(_QueryCustomerReadQuery?.data?.CustomerRead)?.Billing?.email,
          'billingPeriod': _.first(_QueryCustomerReadQuery?.data?.CustomerRead)?.Billing?.period,
          'billingAddress': _.first(_QueryCustomerReadQuery?.data?.CustomerRead)?.Billing?.address,
          'enableAutoInvoicing': _.first(_QueryCustomerReadQuery?.data?.CustomerRead)?.Billing?.enableAutoInvoicing,
          'alertAmount': _.first(_QueryCustomerReadQuery?.data?.CustomerRead)?.Credit?.alertAmount,
          'currency': _.first(_QueryCustomerReadQuery?.data?.CustomerRead)?.Billing?.currency,
          'country': _.first(_QueryCustomerReadQuery?.data?.CustomerRead)?.country,
          'phone': _.first(_QueryCustomerReadQuery && _QueryCustomerReadQuery.data ? _QueryCustomerReadQuery.data.CustomerRead : {})?.phone,
          'smsType': _.first(_QueryCustomerReadQuery?.data?.CustomerRead)?.Billing?.smsType,
          'overDraftLimit': _.first(_QueryCustomerReadQuery?.data?.CustomerRead)?.overDraftLimit
        }

        // Update state.
        return setForceReRender(String.random(8))
      }

      // Report failure.
      return void 0
    }; _Async().catch(i => i)
  }, [passOn, isOpen])

  // Const assignment.
  const _isLoading = MutationCustomerAccountCreateResponse.loading || MutationCustomerAccountAttachSmppResponse.loading || MutationAccountRegisterWithEmailResponse.loading || MutationCustomerUpdateResponse.loading || MutationCustomerCreateResponse.loading || MutationCustomerAttachRateResponse?.loading || MutationCustomerAttachRouteResponse?.loading
  const _isInputDisabled = _.isEmpty(_.first(_QueryCustomerAccountRead?.data?.CustomerAccountRead)?.Route) || _.isEmpty(_QueryCustomerAccountRead?.data?.CustomerAccountRead).Rate ? true : (isCreateOnly ? false : _isLoading || QueryCustomerReadResponse?.loading || _QueryCustomerAccountRead.loading)

  // Return component.
  return (
    <form style={{ 'width': '100%' }} onSubmit={_SubmitForm} key={forceReRender}>
      <Flex gap='22px' flexDir='column' w='100%'>
        <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
          <MemoizedSelect
            isRequired={true}
            name='type'
            label='Customer Type'
            placeholder='Select Customer Type'
            error={error}
            isInvalid={error?.includes('type')}
            options={['RETAIL']}
            data='RETAIL'
            disabled={true}
          />
          <MemoizedInput
            disabled={_isInputDisabled}
            isRequired={true}
            name='companyName'
            label='Company Name'
            placeholder='Enter Company Name'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            error={error}
            isInvalid={error?.includes('companyName')}
            data={_formDataRef?.current?.companyName}
          />
        </Flex>
        <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
          <MemoizedInput
            disabled={_isInputDisabled}
            isRequired={true}
            name='companyReferenceNumber'
            label='Company Reference Number'
            placeholder='Enter Company Reference Number'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            error={error}
            isInvalid={error?.includes('companyReferenceNumber')}
            data={_formDataRef?.current?.companyReferenceNumber}
          />
          <MemoizedSelect
            disabled={_isInputDisabled}
            isRequired={true}
            name='country'
            label='Country'
            placeholder='Select Country'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            error={error}
            isInvalid={error?.includes('country')}
            options={Object.React.App.enums.COUNTRY_LIST.enums?.map(i => i.key)}
            data={_formDataRef?.current?.country?.toUpperCase()}
          />
        </Flex>
        <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
          <MemoizedInput
            disabled={_isInputDisabled}
            isRequired={true}
            name='companyEmail'
            label='Company Email'
            placeholder='Enter Company Email'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            error={error}
            isInvalid={error?.includes('companyEmail')}
            type='email'
            data={_formDataRef?.current?.companyEmail}
          />
          <MemoizedInput
            disabled={_isInputDisabled}
            name='supportEmail'
            label='Support Email'
            type='email'
            placeholder='Enter Support Email'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            error={error}
            isInvalid={error?.includes('supportEmail')}
            data={_formDataRef?.current?.supportEmail}
          />
        </Flex>
        <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
          <MemoizedInput
            disabled={_isInputDisabled}
            isRequired={true}
            name='billingEmail'
            label='Billing Email (Separated with ,)'
            placeholder='Enter Billing Email'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            error={error}
            isInvalid={error?.includes('billingEmail')}
            type='email'
            isMultiple={true}
            separators={[',', 'Enter', 'Tab', ' ']}
            data={_formDataRef?.current?.billingEmail}
          />
          <MemoizedInput
            disabled={_isInputDisabled}
            name='rateEmail'
            label='Rate Email (Separated with ,)'
            placeholder='Enter Rate Email'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            error={error}
            isInvalid={error?.includes('rateEmail')}
            type='email'
            isMultiple={true}
            separators={[',', 'Enter', 'Tab', ' ']}
            data={_formDataRef?.current?.rateEmail}
          />
        </Flex>
        <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
          <MemoizedInput
            disabled={_isInputDisabled}
            isRequired={true}
            name='billingAddress'
            label='Billing Address'
            placeholder='Enter Billing Address'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            error={error}
            isInvalid={error?.includes('billingAddress')}
            data={_formDataRef?.current?.billingAddress}
          />
          <MemoizedSelect
            disabled={_isInputDisabled}
            isRequired={true}
            name='currency'
            label='Currency'
            placeholder='Select Customer Currency'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            error={error}
            isInvalid={error?.includes('currency')}
            options={Object.React.App.enums.CURRENCY_LIST.enums?.map(i => i.key)}
            data={_formDataRef?.current?.currency?.toUpperCase()}
          />
        </Flex>
        <MemoizedSelect
          disabled={_isInputDisabled}
          isRequired={true}
          name='billingPeriod'
          label='Billing Period'
          placeholder='Select Billing Period'
          onChange={({ target }) => {
            // Over spreading.
            const { name, value } = target

            // Update form data.
            _formDataRef.current = {
              ..._formDataRef?.current,
              [name]: value
            }
          }}
          error={error}
          isInvalid={error?.includes('billingPeriod')}
          options={Object.React.App.enums.BILLING_PERIOD.enums?.map(i => i.key)}
          data={_formDataRef?.current?.billingPeriod}
        />
        <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
          <MemoizedInput
            disabled={_isInputDisabled}
            isRequired={true}
            name='phone'
            label='Phone'
            placeholder='Enter Phone Number'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            error={error}
            isInvalid={error?.includes('phone')}
            data={_formDataRef?.current?.phone}
          />
          <MemoizedSelect
            disabled={_isInputDisabled}
            name='smsType'
            label='SMS Type'
            placeholder='Select customer sms type'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            error={error}
            isInvalid={error?.includes('smsType')}
            options={Object.React.App.enums.SMS_TYPE.enums?.map(i => i.key)}
            data={_formDataRef?.current?.smsType?.toUpperCase()}
          />
        </Flex>
        <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
          <MemoizedInput
            disabled={_isInputDisabled}
            isRequired={true}
            name='overDraftLimit'
            label='Over Draft Limit'
            placeholder='Enter overdraft limit'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: isNaN(parseFloat(value)) ? 0 : parseFloat(value)
              }
            }}
            error={error}
            isInvalid={error?.includes('overDraftLimit')}
            data={_formDataRef?.current?.overDraftLimit}
            type='number'
          />
          <MemoizedInput
            disabled={_isInputDisabled}
            name='alertAmount'
            label='Alert Amount'
            placeholder='Enter alert amount'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: parseFloat(value)
              }
            }}
            error={error}
            isInvalid={error?.includes('alertAmount')}
            data={_formDataRef?.current?.alertAmount}
            type='number'
          />
        </Flex>
      </Flex>
      <SubmitButton
        isLoading={_isLoading}
        defaultText={isCreateOnly ? 'Create Customer' : 'Update Customer'}
        disabled={_isInputDisabled}
        onSubmit={_SubmitForm}
      />
    </form>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'isCreateOnly': PropTypes.bool,
  'isOpen': PropTypes.bool,
  'onClose': PropTypes.func,
  'passOn': PropTypes.object.isRequired,
  'customer': PropTypes.object.isRequired
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'customer': __state.Customer, 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
