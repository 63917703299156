/*
 * IMPORTS
 */
import 'react-date-range/dist/styles.css' // Npm: react date range module.
import 'react-date-range/dist/theme/default.css' // Npm: React date range module.
import Moment from 'moment' // Npm: Moment.js library.
import React from 'react' // Npm import: React.js library.
import PropTypes from 'prop-types' // Npm import: Prop types validator.
import { BiChevronDown } from 'react-icons/bi' // Npm import: React icons.
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Skeleton
} from '@chakra-ui/react' // Npm import: Chakra UI components.

import Calendar from './Calendar'
import './index.style.css'

const Index = ({ bg, color, startColor, endColor, asFatButton, startDate, endDate, setSelectDate, calendarOnly, loading }) => {
  // Get current UTC date
  const _now = new Date()
  _now.setUTCHours(0, 0, 0, 0)

  // Get tomorrow in UTC
  const _tomorrow = new Date(Moment.utc().add(1, 'day'))
  _tomorrow.setUTCHours(0, 0, 0, 0)

  const [turnOnCompare, setTurnOnCompare] = React.useState(false)
  const [state, setState] = React.useState({
    'selection': {
      'startDate': startDate ? new Date(Date.UTC(
        new Date(startDate).getUTCFullYear(),
        new Date(startDate).getUTCMonth(),
        new Date(startDate).getUTCDate()
      )) : new Date(_now),
      'endDate': endDate ? new Date(Date.UTC(
        new Date(endDate).getUTCFullYear(),
        new Date(endDate).getUTCMonth(),
        new Date(endDate).getUTCDate()
      )) : new Date(_tomorrow),
      'key': 'selection'
    }
  })


  const _DateFormat = (_state) => {
    if (!_state?.selection?.startDate || !_state?.selection?.endDate) return ''

    const _startDate = new Date(_state.selection.startDate)
    const _endDate = new Date(_state.selection.endDate)

    // Format dates using UTC methods
    const startFormatted = new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      timeZone: 'UTC'
    }).format(_startDate)

    const endFormatted = new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      timeZone: 'UTC'
    }).format(_endDate)

    const year = _endDate.getUTCFullYear()

    return `${startFormatted} - ${endFormatted}, ${year}`
  }

  return (
    <Flex alignItems='center' className={['dateRangePicker', asFatButton ? 'asFatButton' : void 0]?.join(' ')}>
      <Menu>
        {({ isOpen, onClose }) => (
          <>
            {loading ? (
              <Skeleton
                borderRadius='12px'
                width='200px'
                startColor={startColor ?? '#ffffff'}
                endColor={endColor ?? '#f3f2f1'}
                height='45px'
              />
            ) : (
              <MenuButton
                isActive={isOpen}
                bg={bg ?? '#F4F7FE'}
                border='none'
                fontSize='12px'
                fontWeight='700'
                textAlign='left'
                id='edit_product'
                variant='main'
                h='28px'
                borderRadius='12px'
                as={Button}
                disabled={loading}
                color={color ?? '#828797'}
                rightIcon={asFatButton ? void 0 : <BiChevronDown />}>
                {_DateFormat(state)}
              </MenuButton>
            )}
            <MenuList padding={0} border='none' overflow='hidden' borderRadius={22} boxShadow='2xl' zIndex={100}>
              <Calendar
                setTurnOnCompare={setTurnOnCompare}
                turnOnCompare={turnOnCompare}
                onChange={item => {
                  // new dates are in UTC with correct day
                  if (item.selection) {
                    const newSelection = {
                      ...item.selection,
                      startDate: new Date(Date.UTC(
                        item.selection.startDate.getFullYear(),
                        item.selection.startDate.getMonth(),
                        item.selection.startDate.getDate()
                      )),
                      endDate: new Date(Date.UTC(
                        item.selection.endDate.getFullYear(),
                        item.selection.endDate.getMonth(),
                        item.selection.endDate.getDate()
                      ))
                    }
                    setState({ selection: newSelection })
                  }
                }}
                months={1}
                onClose={onClose}
                _handleSetDate={setSelectDate(state)}
                calendarOnly={calendarOnly}
                direction='vertical'
                scroll={true}
                ranges={[state.selection]}
                showDateDisplay={true}
              />
            </MenuList>
          </>
        )}
      </Menu>
    </Flex>
  )
}

Index.propTypes = {
  'startDate': PropTypes.instanceOf(Date),
  'endDate': PropTypes.instanceOf(Date),
  'calendarOnly': PropTypes.bool,
  'loading': PropTypes.bool,
  'asFatButton': PropTypes.bool,
  'setSelectDate': PropTypes.func,
  'bg': PropTypes.string,
  'color': PropTypes.string,
  'startColor': PropTypes.string,
  'endColor': PropTypes.string
}

export default Index