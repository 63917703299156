/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: Prop types for checking props type.
import _ from 'underscore' // Npm: utility module.
import Moment from 'moment' // Npm: Moment.js library for date and time formatting.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useQuery, useLazyQuery } from '@apollo/client' // Npm: Apollo client for GraphQL queries and mutations.
import {
  Flex,
  Table,
  TableContainer,
  useBreakpointValue,
  Tbody,
  Td,
  Thead,
  Tr
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * SIBLINGS
 */
import TableSpinner from 'components/TableSpinner'
import DownloadToExcel from 'components/DownloadToExcel'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'


/*
 * GRAPHS
 */
import RatePlanReadQuery from './__query__/index.ratePlan.read.query'
import CustomerAccountReadQuery from './__query__/index.customerAccount.read.query'


/*
 * STYLES
 */
import {
  cellStyle,
  headerStyle,
  rowStyle
} from './index.style'


/*
 * OBJECTS
 */
const Index = ({ customer }) => {
  // Const assignment.
  const _skipDifference = 12
  const _successFlags = Object.React.App.enums.GRAPHQL_SUCCESSFUL_QUERY_FLAGS.enums.map(i => i.key)

  // Hook assignment.
  const [ratePlan, setRatePlan] = React.useState([])
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const [skipPage, setSkipPage] = React.useState(0)
  const _QueryCustomerAccountRead = useQuery(CustomerAccountReadQuery, { 'variables': { 'customerAccountId': customer?.CustomerAccount?.[0]?.id, 'skip': skipPage * skipDifference, 'take': skipDifference }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const [QueryRatePlanRead, QueryRatePlanReadResponse] = useLazyQuery(RatePlanReadQuery)
  const _tableHeaderHeightRef = React.useRef(0)
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Event handler.
  React.useEffect(() => {
    // Async handler.
    const _Async = async () => {
      // Only proceed if customer read is successful.
      if ('READ_SUCCESSFUL' === _.first(_QueryCustomerAccountRead.data?.CustomerAccountRead)?.status) {
        // Const assignment.
        const _rate = _.first(_QueryCustomerAccountRead?.data?.CustomerAccountRead)?.Rate

        // Only fetch if given customer read has rate
        // attached to it.
        if (!_.isEmpty(_rate)) {
          // Fetch rate plan read.
          const _QueryRatePlanRead = await QueryRatePlanRead({ 'variables': { 'rateId': _rate?.id, 'skip': skipPage * skipDifference, 'take': skipDifference } })

          // If getting data caught exception then report failure.
          if (_QueryRatePlanRead.error instanceof Error) throw _QueryRatePlanRead.error

          // If rate plan read is successful then update state.
          setRatePlan(_QueryRatePlanRead.data?.RatePlanRead)
        }
      }
    }; _Async().catch(i => { throw i })
  }, [_QueryCustomerAccountRead])

  // Return component.
  return (
    <>
      <Flex overflow='auto' className='priceAndCoverage base'>
        <Flex
          display='flex'
          flex={1}
          flexDirection='column'
          gap={_isCurrentViewMobile ? '12px' : '22px'}
          bg='white'
          h='-webkit-fill-available'
          borderRadius='20px'
          p={_isCurrentViewMobile ? '12px' : '22px'}>
          <Flex pb='0px' justify='space-between'>
            <DownloadToExcel
              cellsData={0 === ratePlan?.length || !_.every(_.pluck(ratePlan, 'status'), i => 'READ_SUCCESSFUL' === i) ? [] : ratePlan?.map((item, __index) => ({
                'S.No.': `${(__index + (skipDifference * (skipPage))) + 1}.`,
                'Mcc': item?.Mcc?.mcc ?? '-',
                'Mnc': item?.Mnc?.mnc ?? '-',
                'Country': item?.Mnc?.countryName ?? '-',
                'Operators': item?.Mnc?.network ?? '-',
                'SmsType': item?.smsType ?? '-',
                'Rate': item?.rate ?? '-',
                'RateName': item?.Rate?.displayName ?? '-',
                'CreatedAt': item.createdAt,
                'UpdatedAt': item?.updatedAt ?? '-',
              }))}
              headersData={[
                'S.No.',
                'Mcc',
                'Mnc',
                'Country',
                'Operators',
                'SmsType',
                'Rate',
                'RateName',
                'CreatedAt',
                'UpdatedAt',
              ].map(i => ({ 'key': i, 'label': i }))} />
          </Flex>
          <TableContainer
            flex={1}
            display='flex'
            overflowY='scroll'
            borderRadius='15px'
            outline='1px solid #C5CFE8'>
            <Table variant='simple'>
              <Thead ref={_tableHeaderHeightRef}>
                <Tr style={headerStyle} position='sticky' top={0} zIndex='1'>
                  <Td
                    style={rowStyle}
                    textAlign='center'
                    px='0px'
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    S.No.
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Mcc
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Mnc
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Country
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Operators
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Sms Type
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Rate
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Rate Name
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    CreatedAt
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    UpdatedAt
                  </Td>

                </Tr>
              </Thead>
              {QueryRatePlanReadResponse.loading ? (
                <TableSpinner isLoading={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : (0 === ratePlan?.length || (0 < ratePlan?.length && !_.every(_.pluck(ratePlan, 'status'), j => _successFlags.includes(j)))) ? (
                <TableSpinner isLoading={false} isEmpty={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : (
                <Tbody style={cellStyle}>
                  {ratePlan?.map((item, __index) => (
                    <Tr key={String.random(8)}>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {' '}
                        {`${(__index + (skipDifference * (skipPage))) + 1}.`}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item?.Mcc?.mcc}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item?.Mnc?.mnc ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item?.Mcc?.countryName ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item?.Mnc?.network ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item?.smsType ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item?.rate ? `${item?.rate} ${item?.Rate?.currency}` : '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        w='100%'
                        pt='18px'
                        pb='18px'
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item?.Rate?.displayName ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(item.createdAt).format('YYYY-MM-DD hh:mm:ss A')}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(item.updatedAt).format('YYYY-MM-DD hh:mm:ss A')}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </Flex>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.first(ratePlan)?._totalCount}
        onPageChange={setSkipPage}
      />
    </>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'customer': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'customer': __state.Customer })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
