/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
  query RouteReadQuery($customerId: ID, $type: CUSTOMER_TYPE, $take: PositiveInt, $skip: Int, $search: String) {
    RouteRead(customerId: $customerId, type: $type, take: $take, skip: $skip, search: $search) {
      id,
      createdAt,
      updatedAt,
      displayName,
      type,
      message,
      status,
      _totalCount,
      RoutePlan {
        id,
        isPaused,
        VendorAccount {
          id
        }
        Mcc {
          mcc
        },
        Mnc {
          mnc,
          network
        }
      }
    }
  }
`
