/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query CustomerReadQuery($customerId: ID!) {
  CustomerRead(customerId: $customerId, returnDeliveriesOfTrunk: false) {
    id,
    type,
    country,
    supportEmail,
    overDraftLimit,
    rateEmail,
    phone,
    message,
    status
    Company {
      id,
      displayName,
      referenceNumber,
      email,
    }
    CustomerAccount {
      id,
      Rate {
        id
      }
      Route {
        id,
        RoutePlan {
          id,
          Mcc {
            id,
            mcc
          }
          Mnc {
            id,
            mnc
          }
          VendorAccount {
            id
          }
        }
      }
    }
    Billing {
      id,
      email,
      smsType,
      alertAmount,
      enableAutoInvoicing,
      period,
      currency,
      address
    }
    Credit {
      id,
      limit,
      balance,
      alertAmount
    }
    CustomerOfCustomer {
      id,
      AccountLogin {
        id,
        thumbnailStoredAt,
        displayName,
        email
      }
    }
  }
}
`
