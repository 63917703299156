/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query CreditHistoryReadQuery($customerId: ID, $creditId: ID, $take: PositiveInt, $skip: Int) {
  CreditHistoryRead(customerId: $customerId, creditId: $creditId, take: $take, skip: $skip) {
    id,
    createdAt,
    updatedAt,
    limit,
    balance,
    alertAmount,
    creditedBy,
    message,
    status
  }
}
`
