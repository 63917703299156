/*
 * IMPORTS
 */
import React from 'react' // Npm: React.js library.
import PropTypes from 'prop-types' // Npm: React.js library.
import Stringg from 'string' // Npm: String manipulation library.
import _ from 'underscore' // Npm: Utility module.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useLazyQuery, useQuery } from '@apollo/client' // Npm: Apollo client.
import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useStyleConfig
} from '@chakra-ui/react' // Npm: A simple, modular and accessible component library for React.js.


/*
 * PACKAGES
 */
import DateRangePicker from 'components/DateRangePicker'
import TableSpinner from 'components/TableSpinner'
import { MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import CustomerTrafficQuery from './__query__/index.customer.traffic.query'
import CustomerAccountReadQuery from './__query__/index.customerAccount.read.query'


/*
 * OBJECTS
 */
function Index({ title, account, customer }) {
  // Hook assignment.
  const [smsRead, setSmsRead] = React.useState([])
  const [customerAccount, setCustomerAccount] = React.useState(void 0)
  const [dateRange, setDateRange] = React.useState({
    'selection': {
      'startDate': Object.DateRangeProvider()?.startDate,
      'endDate': Object.DateRangeProvider()?.endDate,
      'key': 'selection'
    }
  })
  const [QueryCustomerTraffic, QueryCustomerTrafficResponse] = useLazyQuery(CustomerTrafficQuery)
  const _styles = useStyleConfig('Card')
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })
  const _QueryCustomerAccountRead = useQuery(CustomerAccountReadQuery, { 'variables': { 'customerId': customer?.id }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })

  // Event handler.
  React.useEffect(() => {
    // Async handler.
    const _Async = async () => {
      // Refetch data on date range change.
      const { data, error } = await QueryCustomerTraffic({ 'variables': { 'customerId': customer?.id, 'startDate': dateRange.selection.startDate, 'endDate': dateRange.selection.endDate } })

      // If getting data caught exception then report failure.
      if (error instanceof Error) throw error

      // Const assignment.
      const _report = _.compact(Object.entries(data?.CustomerTraffic?._report ?? {}))

      // If data is not empty then update it.
      if (0 < _report?.length) {
        // Const assignment.
        const _totalsumup = {}

        // Merge all by country names.
        for (const [__, value] of _.compact(Object.values(_report))) {
          // Break by countryName.
          for (const [key, value2] of Object.entries(value)) {
            // If key is equal to customerAccount.
            if (!customerAccount?.includes(__)) continue

            // If country name is not in the list then add it.
            if (!_totalsumup[key]) _totalsumup[key] = value2
          }
        }

        // Update the state.
        setSmsRead(Object.entries(_totalsumup))
      }
    }; _Async().catch(i => { throw i })
  }, [dateRange, customerAccount])

  // Return component.
  return (
    <Box
      __css={_styles}
      align='center'
      display='flex'
      direction='column'
      w='100%'
      p={_isCurrentViewMobile ? '12px' : '22px'}
      gap={_isCurrentViewMobile ? '12px' : '22px'}
      fontSize={['clamp(12px, 1.5vw, 15px)']}
      boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.17)'>
      <Flex justify='space-between' alignItems='center' width='100%' gap='12px'>
        <Flex flexDir={account.accountType === 'RESELLER' ? 'column' : 'row'} w='100%' gap='12px' justifyContent='space-between'>
          <Text
            color='rgba(43,54,116,1)'
            fontWeight='700'
            fontSize={{ 'base': 'sm', 'md': 'md' }}
            textAlign='left'>
            {title ?? 'Your Traffic Report'}
          </Text>
          <DateRangePicker
            setSelectDate={setDateRange}
            isLoading={QueryCustomerTrafficResponse?.loading} />
        </Flex>
        {
          account.accountType === 'RESELLER' ? (
            <Flex gap='12px' flexDir='row' flexWrap='wrap'>
              <MemoizedSelect
                w='180px'
                name='customerId'
                placeholder='Select Account'
                bg='gray.100'
                color='gray'
                data={customerAccount}
                options={(_QueryCustomerAccountRead?.data?.CustomerAccountRead ?? [])?.map(j => j.displayName ? `${j.displayName} (${j.id})` : void 0)}
                onChange={e => setCustomerAccount(e.target?.value)}
              />
            </Flex>
          ) : void 0
        }
      </Flex>
      <TableContainer
        w='100%'
        border='1px solid rgba(216, 227, 252, 1)'
        borderRadius='15px'
        minH='400px'
        maxH='400px'
        overflowY='scroll'>
        <Table colorScheme='brand' size='sm' width='100%'>
          <Thead bg='rgba(244, 247, 254, 1)'>
            <Tr>
              <Th
                height='40px'
                color='rgba(141, 151, 182, 1)'
                fontWeight='300px'
                borderRight='1px solid rgba(216, 227, 252, 1)'
                fontFamily='DM Sans'>
                <Flex justify='center' align='center'>
                  S.No.
                </Flex>{' '}
              </Th>
              <Th
                w='100px'
                height='40px'
                color='rgba(141, 151, 182, 1)'
                fontWeight='300px'
                borderRight='1px solid rgba(216, 227, 252, 1)'
                fontFamily='DM Sans'>
                <Flex justify='center' align='center'>
                  Country Name
                </Flex>{' '}
              </Th>
              <Th
                height='40px'
                color='rgba(141, 151, 182, 1)'
                fontWeight='300px'
                borderRight='1px solid rgba(216, 227, 252, 1)'
                fontFamily='DM Sans'>
                <Flex justify='center' align='center'>
                  TOTAL SENT
                </Flex>{' '}
              </Th>
              <Th
                height='40px'
                color='rgba(141, 151, 182, 1)'
                fontWeight='300px'
                borderRight='1px solid rgba(216, 227, 252, 1)'
                fontFamily='DM Sans'>
                <Flex justify='center' align='center'>
                  Delivered
                </Flex>{' '}
              </Th>
              <Th
                height='40px'
                color='rgba(141, 151, 182, 1)'
                fontWeight='300px'
                borderRight='1px solid rgba(216, 227, 252, 1)'
                fontFamily='DM Sans'>
                <Flex justify='center' align='center'>
                  FAILED
                </Flex>{' '}
              </Th>
            </Tr>
          </Thead>
          {
            QueryCustomerTrafficResponse.loading ? (
              <TableSpinner isLoading={true} />
            ) : 0 === smsRead?.length ? (
              <TableSpinner isLoading={false} isEmpty={true} />
            ) : (
              <Tbody>
                {
                  smsRead.map((sms, index) => _.isEmpty(sms) ? void 0 : (
                    <Tr key={String.random(8)}>
                      <Td
                        height='42px'
                        isNumeric
                        borderRight='1px solid rgba(216,227,252,1)'>
                        {' '}
                        <Text
                          color='rgba(43, 54, 116, 1)'
                          fontWeight='700'
                          textAlign='center'
                          fontSize='13px'>
                          {' '}
                          {index + 1}
                        </Text>
                      </Td>
                      <Td textAlign='center' borderRight='1px solid rgba(216,227,252,1)'>
                        <Text
                          color='rgba(43, 54, 116, 1)'
                          fontWeight='700'
                          fontSize='13px'>
                          {sms?.[0] ?? '-'}
                        </Text>
                      </Td>
                      <Td textAlign='center' borderRight='1px solid rgba(216,227,252,1)'>
                        <Text
                          color='rgba(43, 54, 116, 1)'
                          fontWeight='700'
                          fontSize='13px'>
                          {sms?.[1]?.totalsmscount ?? 0}
                        </Text>
                      </Td>
                      <Td textAlign='center' borderRight='1px solid rgba(216,227,252,1)'>
                        {' '}
                        <Text
                          color='rgba(43, 54, 116, 1)'
                          fontWeight='700'
                          fontSize='13px'>
                          {' '}
                          <Flex justify='center' align='center'>
                            {sms?.[1]?.deliveredcount ?? 0}
                          </Flex>
                        </Text>{' '}
                      </Td>
                      <Td textAlign='center' borderRight='1px solid rgba(216,227,252,1)'>
                        {' '}
                        <Text
                          color='rgba(43, 54, 116, 1)'
                          fontWeight='700'
                          fontSize='13px'>
                          {' '}
                          <Flex justify='center' align='center'>
                            {sms?.[1]?.failedcount ?? 0}
                          </Flex>
                        </Text>{' '}
                      </Td>
                    </Tr>
                  ))
                }
              </Tbody>
            )
          }
        </Table>
      </TableContainer>
    </Box>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'customer': PropTypes.object.isRequired,
  'account': PropTypes.object.isRequired,
  'title': PropTypes.string
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'customer': __state.Customer, 'account': __state.Account })


/*
 * EXPORTS
 */
export default connect(_MapStateToProps)(Index)
